<template>
  <el-dialog title="编辑人员信息" v-model="dialogFormVisible" @close="$emit('cancel')">
    <el-form :model="myform">
      <div class="h-box">
        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>员工姓名:</div>
          <span style="width: 150px; margin-left: 16px">{{myform.workName}}</span>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>工作日期:</div>
          <span style="width: 150px; margin-left: 16px">{{myFormatDate2(myform.dateTime)}}</span>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>出勤情况:</div>

          <el-radio-group v-model="myform.workType" style="margin-left: 16px">
            <el-radio :label="'FULL'" style="margin-right: 16px">全勤</el-radio>
            <el-radio :label="'HALF'" style="margin-right: 16px">半勤</el-radio>
            <el-radio :label="'NULL'" style="margin-right: 16px">缺勤</el-radio>
          </el-radio-group>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>工作时长:</div>
          <el-time-select
              v-model="myform.workTime"
              start="00:00"
              step="00:30"
              end="12:00"
              placeholder="选择时长" style="width: 150px; margin-left: 16px">
          </el-time-select>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>加班时长:</div>
          <el-time-select
              v-model="myform.overTime"
              start="00:00"
              step="00:30"
              end="12:00"
              placeholder="选择时长" style="width: 150px; margin-left: 16px">
          </el-time-select>
        </div>

      </div>
    </el-form>
    <template #footer>
            <span class="dialog-footer">
                <el-button @click="$emit('cancel')">取 消</el-button>
                <el-button type="primary" @click="submit(myform)">确 定</el-button>
             </span>
    </template>
  </el-dialog>
</template>

<script>
import {computed} from 'vue'
import VueCronLinux from "./VueCronLinux";
import {formatDate} from '../js/formatDate.js';
export default {
  name: "WorkstatEditDialog",
  components: {VueCronLinux},
  props:{
    visible:{type:Boolean, default:false},
    lands:{type:Array, default:[]},
    form: {
      type:Object,
      default(){
        return{
          formType: 0,   //0新增 1编辑
          workName:'',

          belongWorkID: 0,
          dateTime: 0,

          workType: 'NULL',
          overDuration:0,
          workDuration:0,

          overTime:'00:00',
          workTime:'00:00',

        }
      }
    },
  },
  setup(props){//提供的函数props直接用就完事了嗷~~~

  },
  data() {
    return {
      dialogFormVisible: this.visible,
      myform: this.form,
      directorList:[],
      formLabelWidth: '120px'
    };
  },
  watch: {
    form(newV, oldV) {
      console.error("form:", newV)
      if (newV.workDuration > 0){
        let m = newV.workDuration%60
        let h = ((newV.workDuration-m)/60).toFixed(0)
        newV.workTime = (h<9?'0'+h:h) + ':' + (m<9?'0'+m:m);
      }else{
        newV.workTime = '00:00'
      }

      if (newV.overDuration > 0){
        let m = newV.overDuration%60
        let h = ((newV.overDuration-m)/60).toFixed(0)
        newV.overTime = (h<9?'0'+h:h) + ':' + (m<9?'0'+m:m);
      }else{
        newV.overTime = '00:00'
      }

      console.error(newV.workTime + " _ " + newV.overTime)
      this.myform = newV
    },
    visible(newV, oldV) {
      this.dialogFormVisible = newV
    },
  },
  methods:{
    myFormatDate2(time) {
      if (!time) return "-";
      let date = new Date(time);
      let str = formatDate(date, 'yyyy-MM-dd');
      return str;
    },
    submit(myf){
      console.error("myf:", myf)
      if (myf.workType == null || myf.workType.length<1){
        this.$message.error("请选择出勤情况!" );
        return
      }
      if (myf.workTime == null || myf.workTime.length<1){
        this.$message.error("请选择工作时长!" );
        return
      }
      if (myf.overTime == null || myf.overTime.length<1){
        this.$message.error("请选择加班时长!" );
        return
      }

      let workTArr = myf.workTime.split("\:");
      console.error("workTArr:", workTArr)
      myf.workDuration = Number.parseInt(workTArr[0]*60) + Number.parseInt(workTArr[1]);

      let overTArr = myf.overTime.split("\:");
      myf.overDuration = Number.parseInt(overTArr[0]*60) + Number.parseInt(overTArr[1]);


      this.$emit('submit',myf)
    }
  }
}
</script>

<style scoped>
* {
  margin: auto 0;
}
/deep/ .el-date-editor--daterange {
  margin: auto 0;
}
/deep/ .select-trigger {
  margin: auto 0;
}
/deep/ .el-range-editor.el-input__inner{
  padding-top: 0;
  padding-bottom: 0;
}
/deep/ .el-input__suffix {
  line-height: 30px;
}
/deep/ .el-input__inner{
  height: 30px;
  line-height: 30px;
}
/deep/ .el-input__icon{
  line-height: 30px;
}
</style>