<template>
    <el-container style="height: 100%; ">

        <el-container>
            <!--<el-header style="text-align: right; font-size: 12px; height: auto; padding: 0px 10px 10px 20px">
                <div class="v-box" style="padding: 8px; background-color: #fff; border-radius: 5px;">
                    <img :src="coverSrc" class="cover-img" style="width: 100px"/>
                </div>
                <div class="v-box" style="margin: 8px 0 0 0">
                    <el-input v-model="searchForm.name" placeholder="请输入计划名称" style="width: 200px; margin-right: 16px"></el-input>
                    <el-input v-model="searchForm.name" placeholder="请输入批次号" style="width: 200px; margin-right: 16px"></el-input>
                    <el-input v-model="searchForm.name" placeholder="请输入负责人" style="width: 200px; margin-right: 16px"></el-input>
                    <el-select v-model="searchForm.state" placeholder="请选择状态" style="width: 200px; margin-right: 16px">
                        <el-option key="-1" label="全部" value="-1"/>
                        <el-option key="0" label="实施中" value="0"/>
                        <el-option key="1" label="已结束" value="1"/>
                    </el-select>
                    <el-button size="medium" style="margin: 0 8px 0 0">重置</el-button>
                    <el-button size="medium" style="margin: 0 8px 0 0">搜索</el-button>
                    <div style="flex: 1">
                        <el-button size="medium" type="primary" style="margin: 0 0 0 0; float: right" @click="addPlan">新增</el-button>
                    </div>
                </div>
            </el-header>-->

            <el-main style="padding: 0px 10px 10px 20px">
                <div class="container" >
                    <el-table
                            :data="plantPlanList"
                            class="table"
                            ref="multipleTable"
                            header-cell-class-name="table-header"
                            @selection-change="handleSelectionChange"
                            :show-header="true"
                    >
                        <el-table-column label="序号" type="index" width="50">
                        </el-table-column>
                        <el-table-column label="名称" prop="name">
                            <template #default="scope">
                                <p class="nameHint">{{ scope.row.name }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="批次号" prop="id" >
                            <template #default="scope">
                                <p >{{ scope.row.id }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="作物" prop="crop" >
                            <template #default="scope">
                                <p >{{ scope.row.crop }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="地块" prop="belongLandName" >
                            <template #default="scope">
                                <p >{{ scope.row.belongLandName }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="用途" prop="use" >
                            <template #default="scope">
                                <p >{{ scope.row.use }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="面积" prop="area" >
                            <template #default="scope">
                                <p >{{ scope.row.area }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="种植开始日期" prop="cycleStartTime" width="120">
                            <template #default="scope">
                                <p style="white-space: pre-line; text-align: right; width: auto; float: left"  v-html="myFormatDate(scope.row.cycleStartTime)"></p>
                            </template>
                        </el-table-column>
                        <el-table-column label="预期采收日期" prop="harvestTime" width="120">
                            <template #default="scope">
                                <p style="white-space: pre-line; text-align: right; width: auto; float: left"  v-html="myFormatDate(scope.row.harvestTime)"></p>
                            </template>
                        </el-table-column>
                        <el-table-column label="累计收获" prop="harvestWeight" >
                            <template #default="scope">
                                <p >{{ scope.row.harvestWeight }}</p>
                            </template>
                        </el-table-column>

                        <el-table-column label="负责人" prop="director" >
                            <template #default="scope">
                                <p >{{ scope.row.director }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" prop="state" >
                            <template #default="scope">
                                <p >{{ scope.row.state == 0 ? '进行中' : scope.row.state == 1 ? '已完成' : '未知'}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="结束日期" prop="endTime" >
                            <template #default="scope">
                                <p style="white-space: pre-line; text-align: right; width: auto; float: left"  v-html="scope.row.endTime==null || scope.row.endTime<1000 ? '--' : myFormatDate(scope.row.endTime)"></p>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120">
                            <template #default="scope">
                                <div>
                                    <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">查看</el-button>
                                    <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px"  @click="editPlan(scope.row)">修改</el-button>
                                    <el-button type="text"  style="padding-top: 5px; padding-bottom: 5px; min-height: 26px; color: #F56C6C"  @click="removePlan(scope.row)">移除</el-button>
                                </div>
                            </template>
                        </el-table-column>


                    </el-table>

                </div>
            </el-main>


        </el-container>
        <PlantPlanEditDialog :visible="planDialogVis" :lands="landList" @cancel="planDialogVis = false" :form="planForm" @submit="planSubmit"></PlantPlanEditDialog>

    </el-container>
</template>

<script>
    import PlantPlanEditDialog from "./PlantPlanEditDialog";
    import {formatDate} from '../js/formatDate.js';
    export default {
        name: "PlantPlanManage",
        components: {PlantPlanEditDialog},
        data () {
            return {
                coverSrc:require("../../assets/img/zan_wu.png"),
                plantPlanList:[],
                landList:[],
                searchForm:{
                    id:null,
                    name:null,
                    director:null,
                    state:'-1'
                },
                planDialogVis:false,
                planForm:{
                    formType: 0,   //0新增 1编辑
                    id:null,
                    name: '',
                    belongLandID:'',
                    belongLandName: '',
                    use: '标准种植',
                    area: '',
                    date:'',
                    crop:'',
                    cycleStartTime: '',
                    cycleEndTime: '',
                    harvestDate: '',
                    harvestTime: '',
                    expectWeight: '',
                    director: '',
                    biref:'',
                    createTime:'',
                },
            }
        },
        mounted(){
            this.getList();
            this.getLandList();
        },
        methods:{
            myFormatDate(time) {
                let date = new Date(time);
                let str = formatDate(date, 'hh:mm:ss\n[yyyy-MM-dd]');
                return str.replace('[', '<p style="font-size: 10px; color: #b5b5b5">').replace(']', '</p>');
            },
            getList(){
                this.apiUtils.plantPlanListGet({}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.plantPlanList = res.data.list
                    } else {
                        this.$message.error("获取设备列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            getLandList(){
                this.apiUtils.landAllGet({}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.landList = res.data
                    } else {
                        this.$message.error("获取地块列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            editPlan(row){
                this.planForm = {
                    formType: 1,   //0新增 1编辑
                    id:row.id,
                    name: row.name,
                    belongLandID:row.belongLandID,
                    belongLandName: row.belongLandName,
                    use: row.use,
                    area: row.area,
                    crop:row.crop,
                    cycleStartTime:row.cycleStartTime,
                    cycleEndTime: row.cycleEndTime,
                    harvestTime:row.harvestTime,
                    expectWeight:row.expectWeight,
                    director: row.director,
                    biref:row.biref,
                    createTime:row.createTime,
                }
                this.planDialogVis = true;
            },
            addPlan(){
                this.planForm = {
                    formType: 0,   //0新增 1编辑
                    id:null,
                    name: '',
                    belongLandID:'',
                    belongLandName: '',
                    use: '标准种植',
                    area: '',
                    date:'',
                    crop:'',
                    cycleStartTime: '',
                    cycleEndTime: '',
                    harvestDate: '',
                    harvestTime: '',
                    expectWeight: '',
                    director: '',
                    biref:'',
                    createTime:'',
                }
                this.planDialogVis = true;
            },
            removePlan(row){
                this.$confirm('此操作将移除该记录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        this.apiUtils.plantPlanRemove({
                            id: row.id,
                        }).then(res => {
                            if (res.code == 200){
                                console.log(res.data);
                                this.getList();
                            } else {
                                this.$message.error("移除种植计划失败! " + res.message);
                                console.log(res);
                            }

                        });
                    })
                    .catch(() => {

                    })
            },
            planSubmit(form){
                console.error(form)
                if (form.formType == 0){
                    this.apiUtils.plantPlanAdd({
                        name: form.name,
                        belongLandID:form.belongLandID,
                        use: form.use,
                        area: form.area,
                        crop:form.crop,
                        cycleStartTime: form.cycleStartTime,
                        cycleEndTime: form.cycleEndTime,
                        harvestTime: form.harvestTime,
                        expectWeight:form.expectWeight,
                        director: form.director,
                        biref:form.biref
                    }).then(res => {
                        if (res.code == 200){
                            console.log(res.data);
                            this.planDialogVis = false;
                            this.getList();
                        } else {
                            this.$message.error("增加种植计划失败! " + res.message);
                            console.log(res);
                        }

                    });
                }else{
                    this.apiUtils.plantPlanEdit({
                        id: form.id,
                        name: form.name,
                        belongLandID:form.belongLandID,
                        use: form.use,
                        area: form.area,
                        crop:form.crop,
                        cycleStartTime: form.cycleStartTime,
                        cycleEndTime: form.cycleEndTime,
                        harvestTime: form.harvestTime,
                        expectWeight:form.expectWeight,
                        director: form.director,
                        biref:form.biref
                    }).then(res => {
                        if (res.code == 200){
                            console.log(res.data);
                            this.planDialogVis = false;
                            this.getList();
                        } else {
                            this.$message.error("编辑种植计划失败! " + res.message);
                            console.log(res);
                        }

                    });
                }

            },
        }
    }
</script>

<style scoped>
    /deep/ .el-table td, /deep/ .el-table th{
        padding: 6px 0;
        font-size: 13px;
    }

    /deep/ .el-table .cell{
        padding-left: 5px;
        padding-right: 5px;
    }
</style>