<template>
  <el-container style="height: 100%; ">

    <el-container>
      <el-header style="text-align: right; font-size: 12px; height: auto; padding: 0px 10px 10px 20px">

      </el-header>

      <el-main style="padding: 0px 10px 10px 20px">
        <div class="container" >
          <el-form :model="myform">
            <div class="h-box">


              <div class="v-box" style="margin-bottom: 8px">
                <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>男工日薪:</div>
                <el-input v-model="myform.manDayPay" type="number"  style="width: 150px; margin-left: 16px"></el-input>
                <div style="width: 30px; height: 40px; text-align: left; line-height: 40px; margin-left: 10px">元</div>

              </div>
              <div class="v-box" style="margin-bottom: 8px">
                <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>男工时薪:</div>
                <el-input v-model="myform.manHourPay" type="number" style="width: 150px; margin-left: 16px"></el-input>
                <div style="width: 30px; height: 40px; text-align: left; line-height: 40px; margin-left: 10px">元</div>
              </div>
              <div class="v-box" style="margin-bottom: 8px">
                <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>女工日薪:</div>
                <el-input v-model="myform.womanDayPay" type="number" style="width: 150px; margin-left: 16px"></el-input>
                <div style="width: 30px; height: 40px; text-align: left; line-height: 40px; margin-left: 10px">元</div>
              </div>
              <div class="v-box" style="margin-bottom: 8px">
                <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>女工时薪:</div>
                <el-input v-model="myform.womanHourPay" type="number" style="width: 150px; margin-left: 16px"></el-input>
                <div style="width: 30px; height: 40px; text-align: left; line-height: 40px; margin-left: 10px">元</div>
              </div>


            </div>
          </el-form>
        </div>

      </el-main>

      <el-footer>
        <div class="v-box" style="margin: 8px 0 0 0">
          <el-button size="medium" style="margin: 0 8px 0 0" @click="submit(myform)">确认</el-button>
          <el-button size="medium" style="margin: 0 8px 0 0" @click="refresh()">取消</el-button>
        </div>
      </el-footer>
    </el-container>

  </el-container>
</template>

<script>
export default {
  name: "PaySetting",
  data() {
    return {
      myform: {
        manDayPay:0,
        manHourPay:0,
        womanDayPay:0,
        womanHourPay:0
      }
    }
  },
  mounted(){
    this.refresh();

  },
  methods:{
    refresh(){
      this.apiUtils.syWorkerPayInfoGet({}).then(res => {
        if (res.code == 200){
          this.myform = res.data
        } else {
          this.$message.error("薪资获取失败! " + res.message);
          console.log(res);
        }

      });
    },
    submit(myf){
      if (myf.manDayPay == null || myf.manDayPay<0){
        this.$message.error("请填写正确男工日薪!" );
        return
      }
      if (myf.manHourPay == null || myf.manHourPay<0){
        this.$message.error("请填写正确男工时薪!" );
        return
      }
      if (myf.womanDayPay == null || myf.womanDayPay<0){
        this.$message.error("请填写正确女工日薪!" );
        return
      }
      if (myf.womanHourPay == null || myf.womanHourPay<0){
        this.$message.error("请填写正确女工时薪!" );
        return
      }
      this.apiUtils.syWorkerPayEdit({

        manDayPay: myf.manDayPay,
        manHourPay: myf.manHourPay,
        womanDayPay: myf.womanDayPay,
        womanHourPay: myf.womanHourPay,
      }).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.refresh();
        } else {
          this.$message.error("薪资设置失败! " + res.message);
          console.log(res);
        }

      });
    }
  }
}
</script>

<style scoped>

</style>