<template>
    <el-container style="height: 100%; ">

        <el-container>
            <!--<el-header style="text-align: right; font-size: 12px; height: auto; padding: 0px 10px 10px 20px">
                <div class="v-box" style="padding: 8px; background-color: #fff; border-radius: 5px;">
                    <img :src="coverSrc" class="cover-img" style="width: 100px"/>
                </div>
                <div class="v-box" style="margin: 8px 0 0 0">
                    <el-input v-model="searchForm.name" placeholder="请输入计划名称" style="width: 200px; margin-right: 16px"></el-input>
                    <el-input v-model="searchForm.name" placeholder="请输入批次号" style="width: 200px; margin-right: 16px"></el-input>
                    <el-input v-model="searchForm.name" placeholder="请输入负责人" style="width: 200px; margin-right: 16px"></el-input>
                    <el-select v-model="searchForm.state" placeholder="请选择状态" style="width: 200px; margin-right: 16px">
                        <el-option key="-1" label="全部" value="-1"/>
                        <el-option key="0" label="实施中" value="0"/>
                        <el-option key="1" label="已结束" value="1"/>
                    </el-select>
                    <el-button size="medium" style="margin: 0 8px 0 0">重置</el-button>
                    <el-button size="medium" style="margin: 0 8px 0 0">搜索</el-button>
                    <div style="flex: 1">
                        <el-button size="medium" type="primary" style="margin: 0 0 0 0; float: right" @click="addTask">新增</el-button>
                    </div>
                </div>
            </el-header>-->

            <el-main style="padding: 0px 10px 10px 20px">
                <div class="container" >
                    <el-table
                            :data="workTaskList"
                            class="table"
                            ref="multipleTable"
                            header-cell-class-name="table-header"
                            @selection-change="handleSelectionChange"
                            :show-header="true"
                    >
                        <el-table-column label="序号" type="index" width="50">
                        </el-table-column>
                        <el-table-column label="任务编号" prop="id" >
                            <template #default="scope">
                                <p >{{ scope.row.id }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="任务类型" prop="workType" >
                            <template #default="scope">
                                <p >{{ scope.row.workType }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="种植计划" prop="plantPlanName">
                            <template #default="scope">
                                <p class="nameHint">{{ scope.row.plantPlanName }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="批次号" prop="plantPlanID" >
                            <template #default="scope">
                                <p >{{ scope.row.plantPlanID }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="作物" prop="crop" >
                            <template #default="scope">
                                <p >{{ scope.row.crop }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="地块" prop="belongLandName" >
                            <template #default="scope">
                                <p >{{ scope.row.belongLandName }}</p>
                            </template>
                        </el-table-column>

                        <el-table-column label="开始时间" prop="workStartTime" width="100">
                            <template #default="scope">
                                <p style="white-space: pre-line; text-align: right; width: auto; float: left"  v-html="myFormatDate(scope.row.workStartTime)"></p>
                            </template>
                        </el-table-column>
                        <el-table-column label="结束时间" prop="workEndTime" width="100">
                            <template #default="scope">
                                <p style="white-space: pre-line; text-align: right; width: auto; float: left"  v-html="myFormatDate(scope.row.workEndTime)"></p>
                            </template>
                        </el-table-column>
                        <el-table-column label="派发人" prop="director" >
                            <template #default="scope">
                                <p >{{ scope.row.director }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="执行人" prop="worker" >
                            <template #default="scope">
                                <p >{{ scope.row.worker }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" prop="state" >
                            <template #default="scope">
                                <p >{{ scope.row.state == 0 ? '待领取' : scope.row.state == 1 ? '已认领' : scope.row.state == 2 ? '已完成' : '未知' }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="结束日期" prop="endTime" >
                            <template #default="scope">
                                <p style="white-space: pre-line; text-align: right; width: auto; float: left"  v-html="scope.row.endTime==null || scope.row.endTime<1000 ? '--' : myFormatDate(scope.row.endTime)"></p>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120">
                            <template #default="scope">
                                <div>
                                    <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">查看</el-button>
                                    <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px"  @click="editTask(scope.row)">修改</el-button>
                                    <el-button type="text"  style="padding-top: 5px; padding-bottom: 5px; min-height: 26px; color: #F56C6C"  @click="removeTask(scope.row)">移除</el-button>
                                </div>
                            </template>
                        </el-table-column>


                    </el-table>

                </div>
            </el-main>
        </el-container>
        <WorkTaskEditDialog :visible="taskDialogVis" :lands="landList" @cancel="taskDialogVis = false" :form="taskForm" @submit="taskSubmit"></WorkTaskEditDialog>
    </el-container>
</template>

<script>
    import {formatDate} from '../js/formatDate.js';
    import WorkTaskEditDialog from "./WorkTaskEditDialog";
    export default {
        name: "WorkTaskManage",
        components: {WorkTaskEditDialog},
        data () {
            return {
                coverSrc:require("../../assets/img/zan_wu.png"),
                workTaskList:[],
                landList:[],
                searchForm:{
                    id:null,
                    name:null,
                    director:null,
                    state:'-1'
                },
                taskDialogVis:false,
                taskForm:{
                    formType: 0,   //0新增 1编辑
                    id:null,
                    name: '',
                    belongLandID:'',
                    belongLandName: '',
                    use: '标准种植',
                    area: '',
                    date:'',
                    crop:'',
                    cycleStartTime: '',
                    cycleEndTime: '',
                    harvestDate: '',
                    harvestTime: '',
                    expectWeight: '',
                    director: '',
                    biref:'',
                    createTime:'',
                },
            }
        },
        mounted(){
            this.getList();
            this.getLandList();
        },
        methods:{
            myFormatDate(time) {
                let date = new Date(time);
                let str = formatDate(date, 'hh:mm:ss\n[yyyy-MM-dd]');
                return str.replace('[', '<p style="font-size: 10px; color: #b5b5b5">').replace(']', '</p>');
            },
            getList(){
                this.apiUtils.workTaskListGet({}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.workTaskList = res.data.list
                    } else {
                        this.$message.error("获取设备列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            getLandList(){
                this.apiUtils.landAllGet({}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.landList = res.data
                    } else {
                        this.$message.error("获取地块列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            editTask(row){
                this.taskForm = {
                    formType: 1,   //0新增 1编辑
                    id:row.id,
                    name: row.name,
                    belongLandID:row.belongLandID,
                    plantPlanID:row.plantPlanID,
                    plantPlanName:row.plantPlanName,
                    workStartTime:row.workStartTime,
                    workEndTime: row.workEndTime,
                    workType:row.workType,
                    workDescribe:row.workDescribe,
                    worker:row.worker,
                    director: row.director,
                    remark:row.remark,
                    createTime:row.createTime,
                }
                this.taskDialogVis = true;
            },
            addTask(){
                this.taskForm = {
                    formType: 0,   //0新增 1编辑
                    id:null,
                    name: '',
                    plantPlanID:'',
                    plantPlanName: '',
                    workStartTime: '',
                    workEndTime: '',
                    workDescribe:'',
                    workType:'',
                    worker:'',
                    director: '',
                    remark:'',
                    createTime:'',
                }
                this.taskDialogVis = true;
            },
            removeTask(row){
                this.$confirm('此操作将移除该任务, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        this.apiUtils.workTaskRemove({
                            id: row.id,
                        }).then(res => {
                            if (res.code == 200){
                                console.log(res.data);
                                this.getList();
                            } else {
                                this.$message.error("移除农事任务失败! " + res.message);
                                console.log(res);
                            }

                        });
                    })
                    .catch(() => {

                    })
            },
            taskSubmit(form) {
                console.error(form)
                if (form.formType == 0) {
                    this.apiUtils.workTaskAdd({
                        name: form.name,
                        plantPlanID: form.plantPlanID,
                        workStartTime: form.workStartTime,
                        workEndTime: form.workEndTime,
                        workType: form.workType,
                        workDescribe: form.workDescribe,
                        worker: form.worker,
                        remark: form.remark
                    }).then(res => {
                        if (res.code == 200) {
                            console.log(res.data);
                            this.taskDialogVis = false;
                            this.getList();
                        } else {
                            this.$message.error("增加农事任务失败! " + res.message);
                            console.log(res);
                        }

                    });
                } else {
                    this.apiUtils.workTaskEdit({
                        id: form.id,
                        name: form.name,
                        plantPlanID: form.plantPlanID,
                        workStartTime: form.workStartTime,
                        workEndTime: form.workEndTime,
                        workType: form.workType,
                        workDescribe: form.workDescribe,
                        worker: form.worker,
                        remark: form.remark
                    }).then(res => {
                        if (res.code == 200) {
                            console.log(res.data);
                            this.taskDialogVis = false;
                            this.getList();
                        } else {
                            this.$message.error("编辑农事任务失败! " + res.message);
                            console.log(res);
                        }

                    });
                }
            },
        }
    }
</script>

<style scoped>
    /deep/ .el-table td, /deep/ .el-table th{
        padding: 6px 0;
        font-size: 13px;
    }

    /deep/ .el-table .cell{
        padding-left: 5px;
        padding-right: 5px;
    }

</style>