<template>
    <el-dialog title="编辑种植计划" v-model="dialogFormVisible" @close="$emit('cancel')">
        <el-form :model="myform">
            <div class="h-box">
                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>农事任务名称:</div>
                    <el-input v-model="myform.name"  style="width: 150px; margin-left: 16px"></el-input>

                    <div v-show="myform.type==1" style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red"></span>农事任务编号:</div>
                    <span v-show="myform.type==1" style="width: 150px; margin-left: 16px">{{myform.id}}</span>
                </div>

                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>种植批次:</div>
                    <el-cascader v-model="cascaderModel" :props="cascaderProps" style="width: 300px; margin-left: 16px"></el-cascader>

                    <!--<el-select v-model="myform.plantPlanID" placeholder="请选择种植批次" style="width: 150px; margin-left: 16px">
                        <el-option
                                v-for="item in planList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>

                    </el-select>-->
                </div>

                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>农事内容:</div>
                     <el-select v-model="myform.workType" placeholder="请选择农事内容" style="width: 150px; margin-left: 16px">
                         <el-option label="耕地" value="耕地"></el-option>
                         <el-option label="施肥" value="施肥"></el-option>
                         <el-option label="播种" value="播种"></el-option>
                         <el-option label="灌溉" value="灌溉"></el-option>
                         <el-option label="打药" value="打药"></el-option>
                         <el-option label="除草" value="除草"></el-option>
                         <el-option label="修剪" value="修剪"></el-option>
                         <el-option label="日常管理" value="日常管理"></el-option>
                         <el-option label="其它" value="其它"></el-option>
                     </el-select>

                </div>

                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>执行时间:</div>
                    <el-date-picker
                            style="margin-left: 16px"
                            v-model="myform.date"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间">
                    </el-date-picker>
                </div>
                <div class="v-box" style="margin-bottom: 8px;">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px; margin-top: 0px"><span style="color: red"></span>任务描述:</div>
                    <el-input v-model="myform.workDescribe" type="textarea" :rows="5" placeholder="请输入任务描述" style="width: 300px; margin-left: 16px"></el-input>
                </div>
                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red"></span>负责人:</div>
                    <el-select v-model="myform.worker" placeholder="请选择负责人" style="width: 150px; margin-left: 16px">
                        <el-option label="黄瓜" value="ZW0001"></el-option>
                        <el-option label="西红柿" value="ZW0002"></el-option>
                    </el-select>
                </div>

              <!--  <div class="v-box" style="margin-bottom: 8px;">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px; margin-top: 0px"><span style="color: red"></span>备注:</div>
                    <el-input v-model="myform.remark" type="textarea" :rows="5" placeholder="请输入备注" style="width: 300px; margin-left: 16px"></el-input>
                </div>
-->

            </div>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="$emit('cancel')">取 消</el-button>
                <el-button type="primary" @click="submit(myform)">确 定</el-button>
             </span>
        </template>
    </el-dialog>
</template>

<script>
    import {computed} from 'vue'
    import VueCronLinux from "./VueCronLinux";

    let that;
    export default {
        name: "WorkTaskEditDialog",
        components: {VueCronLinux},
        props:{
            visible:{type:Boolean, default:false},
            lands:{type:Array, default:[]},
            form: {
                type:Object,
                default(){
                    return{
                        type: 0,   //0新增 1编辑
                        id:null,
                        name: '',
                        plantPlanID:'',
                        plantPlanName: '',
                        date:'',
                        workStartTime: '',
                        workType:'',
                        workEndTime: '',
                        workDescribe: '',
                        worker:'',
                        director: '',
                        remark:'',
                        createTime:'',
                    }
                }
            },
        },
        setup(props){//提供的函数props直接用就完事了嗷~~~

        },
        data() {
            return {
                dialogFormVisible: this.visible,
                myform: this.form,
                planList:[],
                landList:[],
                directorList:[],
                formLabelWidth: '120px',
                cascaderModel:[],
                cascaderProps:{
                    lazy: true,
                    lazyLoad(node, resolve) {
                        const { level } = node
                        if (level == 0){
                            const nodes = that.landList.map((item) => ({
                                value: item.id,
                                label: item.name,
                                leaf: false,
                            }))
                            resolve(nodes)
                        }else{
                            that.apiUtils.plantPlanUnFinishListGetByLand({landID:node.value}).then(res => {
                                if (res.code == 200){
                                    that.planList = res.data
                                    const nodes = that.planList.map((item) => ({
                                        value: item.id,
                                        label: item.name,
                                        leaf: true,
                                    }))
                                    resolve(nodes)
                                } else {
                                    this.$message.error("获取种植计划列表失败! " + res.message);
                                    console.log(res);
                                }

                            });
                        }
                    },
                }
            };
        },
        mounted(){
            that = this;
        },
        watch: {
            form(newV, oldV) {
                this.myform = newV
                if (!this.myform.date) {
                    this.myform.date = [new Date(), new Date()]
                }
                if (newV.workStartTime != null && newV.workStartTime > 1000){
                    this.myform.date[0] = new Date(newV.workStartTime)
                }
                if (newV.workEndTime != null && newV.workEndTime > 1000){
                    this.myform.date[1] = new Date(newV.workEndTime)
                }
                if (newV.belongLandID != null && newV.plantPlanID != null){
                    this.cascaderModel = [newV.belongLandID, newV.plantPlanID]
                }
                this.myform
            },
            visible(newV, oldV) {
                this.dialogFormVisible = newV
            },
            lands(newV, oldV) {
                this.landList = newV
            }
        },
        methods:{
            submit(myf){
                if (myf.name == null || myf.name.length<1){
                    this.$message.error("请填写农事任务名称!" );
                    return
                }
                if (this.cascaderModel == null || this.cascaderModel.length != 2 || this.cascaderModel[1] == null || this.cascaderModel[1].length<1){
                    this.$message.error("请选择种植批次!" );
                    return
                }

                if (myf.workType == null || myf.workType.length<1){
                    this.$message.error("请选择工作内容!" );
                    return
                }

                if (myf.date == null || myf.date.length!=2){
                    this.$message.error("请选择执行时间!" );
                    return
                }

                myf.plantPlanID = this.cascaderModel[1];
                myf.workStartTime = myf.date[0].getTime();
                myf.workEndTime = myf.date[1].getTime();

                this.$emit('submit',myf)
            }
        }
    }
</script>

<style scoped>
    * {
        margin: auto 0;
    }
    /deep/ .el-date-editor--daterange {
        margin: auto 0;
    }
    /deep/ .select-trigger {
        margin: auto 0;
    }
    /deep/ .el-range-editor.el-input__inner{
        padding-top: 0;
        padding-bottom: 0;
    }
    /deep/ .el-input__suffix {
        line-height: 30px;
    }
    /deep/ .el-input__inner{
        height: 30px;
        line-height: 30px;
    }
    /deep/ .el-input__icon{
        line-height: 30px;
    }
</style>