<template>
    <el-dialog title="编辑种植计划" v-model="dialogFormVisible" @close="$emit('cancel')">
        <el-form :model="myform">
            <div class="h-box">
                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>种植计划名称:</div>
                    <el-input v-model="myform.name"  style="width: 150px; margin-left: 16px"></el-input>

                    <div v-show="myform.formType==1" style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red"></span>种植批次号:</div>
                    <span v-show="myform.formType==1" style="width: 150px; margin-left: 16px">{{myform.id}}</span>
                </div>

                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>种植地块:</div>
                    <el-select v-model="myform.belongLandID" placeholder="请选择地块" style="width: 150px; margin-left: 16px">
                        <el-option
                                v-for="item in landList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>

                    </el-select>

                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>种植面积:</div>
                    <el-input v-model="myform.area" style="width: 100px; margin-left: 16px"></el-input>
                    <span style="height: 40px; line-height: 40px; margin-left: 16px">亩</span>
                </div>

                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>种植作物:</div>
                    <el-input v-model="myform.crop" style="width: 150px; margin-left: 16px"></el-input>
                   <!-- <el-select v-model="myform.crop" placeholder="请选择作物" style="width: 150px; margin-left: 16px">
                        <el-option label="黄瓜" value="ZW0001"></el-option>
                        <el-option label="西红柿" value="ZW0002"></el-option>
                    </el-select>-->

                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*
                    </span>种植用途:</div>
                    <el-select v-model="myform.use" placeholder="请选择种植用途" style="width: 150px; margin-left: 16px">
                        <el-option label="标准种植" value="标准种植"></el-option>
                        <el-option label="育种" value="育种"></el-option>
                        <el-option label="育苗" value="育苗"></el-option>
                    </el-select>
                </div>

                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>计划种植周期:</div>
                    <el-date-picker
                            style="margin-left: 16px"
                            v-model="myform.date"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>预计收获日期:</div>
                    <el-date-picker
                            style="width: 150px; margin-left: 16px"
                            v-model="myform.harvestDate"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </div>

                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>预计收获总量:</div>
                    <el-input v-model="myform.expectWeight" style="width: 150px; margin-left: 16px"></el-input>
                    <span style="height: 40px; line-height: 40px; margin-left: 16px">千克</span>
                </div>

                <div class="v-box" style="margin-bottom: 8px">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red"></span>负责人:</div>
                    <el-select v-model="myform.director" placeholder="请选择负责人" style="width: 150px; margin-left: 16px">
                        <el-option label="黄瓜" value="ZW0001"></el-option>
                        <el-option label="西红柿" value="ZW0002"></el-option>
                    </el-select>
                </div>

                <div class="v-box" style="margin-bottom: 8px;">
                    <div style="width: 100px; height: 40px; text-align: right; line-height: 40px; margin-top: 0px"><span style="color: red"></span>简介:</div>
                    <el-input v-model="myform.biref" type="textarea" :rows="5" placeholder="请输入简介" style="width: 300px; margin-left: 16px"></el-input>
                </div>


            </div>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="$emit('cancel')">取 消</el-button>
                <el-button type="primary" @click="submit(myform)">确 定</el-button>
             </span>
        </template>
    </el-dialog>
</template>

<script>
    import {computed} from 'vue'
    import VueCronLinux from "./VueCronLinux";
    export default {
        name: "PlantPlanEditDialog",
        components: {VueCronLinux},
        props:{
            visible:{type:Boolean, default:false},
            lands:{type:Array, default:[]},
            form: {
                type:Object,
                default(){
                    return{
                        formType: 0,   //0新增 1编辑
                        id:null,
                        name: '',
                        belongLandID:'',
                        belongLandName: '',
                        use: '标准种植',
                        area: '',
                        date:'',
                        crop:'',
                        cycleStartTime: '',
                        cycleEndTime: '',
                        harvestDate: '',
                        harvestTime: '',
                        expectWeight: '',
                        director: '',
                        biref:'',
                        createTime:'',
                    }
                }
            },
        },
        setup(props){//提供的函数props直接用就完事了嗷~~~

        },
        data() {
            return {
                dialogFormVisible: this.visible,
                myform: this.form,
                landList:[],
                directorList:[],
                formLabelWidth: '120px'
            };
        },
        watch: {
            form(newV, oldV) {
                this.myform = newV
                if (newV.harvestTime != null && newV.harvestTime > 1000){
                    this.myform.harvestDate = new Date(newV.harvestTime)
                }
                if (!this.myform.date) {
                    this.myform.date = [new Date(), new Date()]
                }
                if (newV.cycleStartTime != null && newV.cycleStartTime > 1000){
                    this.myform.date[0] = new Date(newV.cycleStartTime)
                }
                if (newV.cycleEndTime != null && newV.cycleEndTime > 1000){
                    this.myform.date[1] = new Date(newV.cycleEndTime)
                }
                this.myform
            },
            visible(newV, oldV) {
                this.dialogFormVisible = newV
            },
            lands(newV, oldV) {
                this.landList = newV
            }
        },
        methods:{
            submit(myf){
                if (myf.name == null || myf.name.length<1){
                    this.$message.error("请填写种植计划名称!" );
                    return
                }
                if (myf.belongLandID == null || myf.belongLandID.length<1){
                    this.$message.error("请选择种植地块!" );
                    return
                }
                if (myf.area == null || myf.area.length<1){
                    this.$message.error("请填写种植面积!" );
                    return
                }

                if (myf.crop == null || myf.crop.length<1){
                    this.$message.error("请填写种植作物!" );
                    return
                }
                if (myf.use == null || myf.use.length<1){
                    this.$message.error("请选择种植用途!" );
                    return
                }

                if (myf.date == null || myf.date.length!=2){
                    this.$message.error("请选择种植周期!" );
                    return
                }
                if (myf.harvestDate == null){
                    this.$message.error("请选择预计收获日期!" );
                    return
                }
                if (myf.expectWeight == null || myf.expectWeight.length<1){
                    this.$message.error("请填写预计收货总量!" );
                    return
                }

                myf.cycleStartTime = myf.date[0].getTime();
                myf.cycleEndTime = myf.date[1].getTime();
                myf.harvestTime = myf.harvestDate.getTime();

                this.$emit('submit',myf)
            }
        }
    }
</script>

<style scoped>
    * {
        margin: auto 0;
    }
    /deep/ .el-date-editor--daterange {
        margin: auto 0;
    }
    /deep/ .select-trigger {
        margin: auto 0;
    }
    /deep/ .el-range-editor.el-input__inner{
        padding-top: 0;
        padding-bottom: 0;
    }
    /deep/ .el-input__suffix {
        line-height: 30px;
    }
    /deep/ .el-input__inner{
        height: 30px;
        line-height: 30px;
    }
    /deep/ .el-input__icon{
        line-height: 30px;
    }
</style>