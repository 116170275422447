<template>
  <div class="content v-box" style="flex-wrap: wrap;" >
    <el-card :body-style="{ padding: '0px', margin: '8px',}"  v-for="item in cameraList" style="margin: 8px; height: fit-content;"
             :key="item.id"
             :label="item.name"
             :value="item.id">
      <el-image @click="onPreview(item)"  :src="item.type == 'IOT-CAMERA' ? 'https://api.iezfarmer.com/iotapi'+item.imageID : 'https://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID" style="height: 200px; width:auto; display: block; cursor: pointer" />
      <div style="padding: 14px;">
        <span>{{ item.groupAlias == null ? item.deviceGroupName : item.groupAlias }}</span>
        <span>{{ item.deviceGroupSN }}</span>
        <div class="bottom">
          <!-- <time class="time">{{ myFormatDate(item.uploadTime) }}</time>-->
          <el-button type="text" class="button" @click="onInfo(item)">查看详情</el-button>
        </div>
      </div>
    </el-card>
    <div class="pagination" style="width: 100%; margin-left: 8px; margin-right: 8px">
      <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[8, 12, 20, 40, 100]"
          :current-page="pageNo"
          :page-size="pageSize"
          :total="count"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {formatDate} from '../js/formatDate.js';

export default {
  name: "DeviceGroupManage2",
  mounted(){
    this.getList();
  },

  data(){
    return{
      cameraList:[],
      pageNo: 1,
      pageSize: 8,
      count:0,
    }
  },
  watch: {


  },
  methods:{
    myFormatDate(time) {
      let date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd HH:mm:ss');
    },
    getList(){
      this.apiUtils.syDeviceGroupPage({pageSize:this.pageSize, pageNo:this.pageNo }).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.cameraList = res.data.list
          this.count = res.data.count

          //   this.pageSize = res.pageSize;
          //  this.pageNo = res.pageNo
        } else{
          this.$message.error("获取列表失败! " + res.message);
          console.log(res);
        }

      });
    },

    onInfo(row){
      let path = '/group/'+row.deviceGroupSN;
      let list = this.$store.state.tagsList
      let isExist = list.some(item => {
        return item.path === path;
      });
      let name = row.groupAlias == null ? row.deviceGroupName : row.groupAlias
      if (!isExist) {
        if (list.length >= 8) {
          this.$store.commit("delTagsItem", { index: 0 });
        }
        this.$store.commit("setTagsItem", {
          name: name,
          title: name,
          path: path
        });
      }

      this.$router.push(path)
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pageSize = size;
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.pageNo = val
      this.getList();
    },
  }
}
</script>

<style scoped>
/deep/ .is-disabled{
  visibility: hidden;
}
/deep/ .el-image__error, /deep/ .el-image__inner, /deep/ .el-image__placeholder{
  width: auto;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}
</style>