<template>
  <div class="v-box" style="flex-wrap: wrap">
    <div>
      <canvas id="myCanvas" width="900" height="900" style=" background: white; border: 0px solid black"></canvas>
    </div>

    <div class="item" @click="autoPlay()">
      <!--      <img :src="item.imgSrc" class="cover-img" />-->
      <span class="item-title">{{isAuto ? '暂停播放' : '自动播放'}}</span>
    </div>

    <div class="item" @click="history()">
      <!--      <img :src="item.imgSrc" class="cover-img" />-->
      <span class="item-title">历史数据</span>
    </div>

    <el-drawer
        v-model="drawerOpen"
        title="历史轨迹"
        direction="rtl"
        @opened="drawerOpened"
        size="40%">
      <div style="display: flex; flex-direction: column; height: 100%">
        <div class="pagination" style="width: 80%; margin-left: 8px; margin-right: 8px">
          <el-pagination
              background
              layout="total, prev, pager, next, jumper"
              :page-sizes="[8, 12, 20, 40, 100]"
              :current-page="pageNo"
              :page-size="pageSize"
              :total="count"
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
          ></el-pagination>
        </div>
<!--        <div style="display: flex; justify-content: flex-start; align-items: center; height: 50px">
          <el-date-picker
              v-model="selectDate"
              @change="dateChange"
              type="date"
              placeholder="选择日期"
          />

          <el-time-picker
              style="margin-left: 20px"
              v-model="selectTime"
              is-range
              range-separator="-"
              @change="timeChange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </div>-->
        <div style="flex-grow: 1; margin-top: 20px" class="table-box" ref="tableBox">
          <el-table :data="tableData" style="width: 100%;"  :max-height="tableHeight+'px'">
            <el-table-column label="序号" type="index" width="50">
            </el-table-column>
            <el-table-column prop="createTime" label="时间" width="155" >
              <template #default="scope">
                <p>{{ myFormatDate(scope.row.createTime) }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="obCount" label="目标数" width="100" />
            <el-table-column prop="obFarCount" label="远目标数" width="100" />
            <el-table-column prop="obNearCount" label="近目标数" width="100" />
            <el-table-column fixed="right" label="操作">
              <template #default="scope">
                <el-button
                    link
                    type="primary"
                    @click="itemClick(scope.row)"
                    size="small">
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>

    </el-drawer>

  </div>
</template>

<script>
import {formatDate} from "@/views/js/formatDate";

export default {
  name: "RadarMap",
  components: {},
  data () {
    return {
      sn:this.$route.params.sn,
      shipsSrc:require("../../assets/img/ships.png"),
      list:[],
      radarInfo:{},
      objectList: [],
      isAuto: false,
      nowTime: null,

      drawerOpen:false, //右侧选择时间的抽屉
      tableData:[],
      tableHeight: 600,
      pageNo: 1,
      pageSize: 16,
      count:0,
      endTime: new Date().getTime(),
    }
  },
  mounted(){
    let account = localStorage.getItem("userInfo") == null ? null
        : JSON.parse(localStorage.getItem("userInfo")).company.account;
    if (account == "syzx"){
      let syList = [{title: '刷新', imgSrc:require("../../assets/img/ships.png")}];
      this.list = syList;
    }

    window.addEventListener("resize", () => {
      this.tableHeight = window.innerHeight - 145;
    })

    this.drawRadar()
    setInterval(() => {
      if (!this.isAuto) return
      this.getLast()
    }, 300);  // 滚动速度

    this.getList();
  },
  methods:{
    myFormatDate(time) {
      if (!time) return '-'
      let date = new Date(time);
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
    },
    history(){
      this.drawerOpen = true
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pageSize = size;
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.pageNo = val
      this.getList();
    },
    handleClose(){},
    drawerOpened(){
      this.tableHeight = window.innerHeight - 145;
    },
    autoPlay(){
      this.isAuto = ! this.isAuto
    },
    itemClick(item){
      this.apiUtils.radarInfo({id:item.id }).then(res => {
        if (res.code == 200){
          console.log(res.data);
          let his = res.data;
          let arr = []
          for (let i = 0; i < his.length; i++) {
            let ob = his[i]
            arr.push({
              x: ob.obDistLat,
              y: ob.obDistLong,
              id: ob.obID,
              a: ob.obAngle.toFixed(2),
              r: ob.obRadian.toFixed(2)
            })
          }
          this.nowTime = item.createTime;
          this.objectList = arr;
          this.drawRadar()
        } else{
          this.$message.error("获取详情失败! " + res.message);
          console.log(res);
        }

      });
    },
    drawRadar(){

      let canvas = document.getElementById("myCanvas");

      console.log(canvas.width + " _ " + canvas.height)

      let padding = 50

      let realyW = canvas.width - 2*padding;
      let realyH = canvas.height - 2*padding;

      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);


      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);





      let xStart = -100;
      let xEnd = 100;
      let yStart = 0;
      let yEnd = 100;

      let xScale = realyW/(xEnd - xStart);
      let yScale = realyH/(yEnd - yStart)

      ctx.lineWidth = 1;

      let hCount = 20;
      let rectH = realyH/hCount;

      ctx.strokeStyle = '#c9c9c9'

      //横线
      for (let i = 0; i < hCount+1; i++) {
        ctx.moveTo(0+padding, rectH * i-0.5+padding);
        ctx.lineTo(realyW+padding, rectH * i-0.5+padding);

      }

      let wCount = 20;
      let rectW = realyW/wCount;


      //竖线
      for(let i=0;i<wCount+1;i++){
        ctx.moveTo(rectW * i-0.5+padding, 0+padding);
        ctx.lineTo(rectW * i-0.5+padding, realyH+padding);

      }
      ctx.stroke();

      ctx.fillStyle = '#575757'
      ctx.font = "15pt Calibri";
      ctx.textBaseline = "middle"
      ctx.textAlign = 'right';
      ctx.fillText("更新时间："+this.myFormatDate(this.nowTime), canvas.width-padding, padding/2);

      ctx.fillStyle = '#575757'
      ctx.font = "15pt Calibri";
      ctx.textBaseline = "middle"
      ctx.textAlign = 'center';

      for(let i=0;i<wCount+1;i++){
        ctx.fillText(yStart+(hCount-i)*rectH/yScale, padding/2, rectH * i-0.5+padding);
        ctx.fillText(xStart+i*rectW/xScale, rectW * i-0.5+padding,canvas.height-padding/2);
      }

      let oW = 20;

      ctx.fillStyle = '#000000'

      ctx.font = "15pt Calibri";
      ctx.textAlign = 'center';
      ctx.textBaseline = "bottom"

      if (this.objectList){
        console.log(this.objectList.length)
        for (let i = 0; i < this.objectList.length; i++) {
          let item = this.objectList[i];
          let x = (item.x - xStart) * xScale + padding;
          let y = realyH+padding - ((item.y - yStart) * yScale + oW/1.73);

          let text = "ID:"+item.id+",A:"+item.a+"°,R:"+item.r+"m";
          ctx.fillText(text,x,y-5);
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(x-oW/2, y+0.866*oW);
          ctx.lineTo(x+oW/2, y+0.866*oW);
          ctx.lineTo(x, y);
          ctx.fill();     // 有填充色
          ctx.closePath();
        }
      }

      ctx.stroke();

    },
    getLast(){
      this.apiUtils.radarLast({sn:this.sn}).then(res => {
        if (res.code == 200){
          this.radarInfo = res.data
          if (!res.data || !res.data.objectList) return;
          this.nowTime = this.radarInfo.createTime;
          let his = res.data.objectList;
          let arr = []
          for (let i = 0; i < his.length; i++) {
            let ob = his[i]
            arr.push({
              x: ob.obDistLat,
              y: ob.obDistLong,
              id: ob.obID,
              a: ob.obAngle.toFixed(2),
              r: ob.obRadian.toFixed(2)
            })
          }
          this.objectList = arr;
          this.drawRadar()
        } else {
          this.$message.error("获取失败! " + res.errInfo);
          console.log(res);
        }

      });
    },
    getList(){
      if (this.pageNo == 1) this.endTime = new Date().getTime();
      this.apiUtils.radarPage({pageSize:this.pageSize, pageNo:this.pageNo, sn:this.sn, endTime: this.endTime }).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.tableData = res.data.list
          this.count = res.data.count
          //   this.pageSize = res.pageSize;
          //  this.pageNo = res.pageNo
        } else{
          this.$message.error("获取列表失败! " + res.message);
          console.log(res);
        }

      });
    },
  }
}
</script>

<style scoped>

.item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  height: 60px;
  line-height: 60px;
  background: white;
  border-radius: 5px;
  text-align: center;
  margin: 8px;
  box-shadow: 0px 0px 1px 1px rgba(148, 162, 162, 0.2);
}
.item:active{
  background: #f3f3f3;
}
.item-title{
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
}
.cover-img{
  width: 150px;
  height: 150px;
}


/deep/ .el-table td, /deep/ .el-table th{
  padding: 6px 0;
  font-size: 13px;
}

/deep/ .el-table .cell{
  padding-left: 5px;
  padding-right: 5px;
}
</style>