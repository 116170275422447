<template>
  <el-container style="height: 100%; ">

    <el-container>
      <el-header style="text-align: right; font-size: 12px; height: auto; padding: 0px 10px 10px 20px">
        <!--          <div class="v-box" style="padding: 8px; background-color: #fff; border-radius: 5px;">
                      <img :src="coverSrc" class="cover-img" style="width: 100px"/>
                  </div>-->
        <div class="v-box" style="margin: 8px 0 0 0">
          <el-date-picker v-model="searchForm.month" @change="searchFormChange"
                          type="month" placeholder="选择月份" style="width: 150px; margin-left: 16px" />

<!--          <el-button size="medium" style="margin: 0 8px 0 0" >查看</el-button>-->
          <el-button size="medium" style="margin: 0 8px 0 0" @click="exportExcel">导出工资表</el-button>
          <div style="flex: 1">
            <el-button size="medium" type="primary" style="margin: 0 0 0 0; float: right" @click="reloadPaysheet">重新生成</el-button>
          </div>
        </div>
      </el-header>

      <el-main style="padding: 0px 10px 10px 20px">
        <div class="container" >
          <el-table
              :data="paysheetList"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              :show-header="true"
          >
            <el-table-column type="expand">
              <template #default="scope">
                <el-form label-position="left" inline style="margin-left: 60px">
                  <el-form-item label="全勤数:" style="margin-bottom: 0px">
                    <span>{{ scope.row.fullCount }}天</span>
                  </el-form-item>
                  <el-form-item label="半勤数:" style="margin-bottom: 0px; margin-left: 20px">
                    <span>{{ scope.row.halfCount }}天</span>
                  </el-form-item>
                  <el-form-item label="缺勤数:" style="margin-bottom: 0px; margin-left: 20px">
                    <span>{{ scope.row.nullCount }}天</span>
                  </el-form-item>
                  <el-form-item label="加班数:" style="margin-bottom: 0px; margin-left: 20px">
                    <span>{{ scope.row.overDayCount }}天</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>

            <el-table-column label="序号" type="index" width="50">
            </el-table-column>

            <el-table-column label="月份" prop="dateTime">
              <template #default="scope">
                <p class="nameHint">{{ myFormatDate2(scope.row.dateTime) }}</p>
              </template>
            </el-table-column>
            <el-table-column label="员工姓名" prop="workName">
              <template #default="scope">
                <p class="nameHint">{{ scope.row.userInfo.name }}</p>
              </template>
            </el-table-column>
            <el-table-column label="工号" prop="belongWorkID" >
              <template #default="scope">
                <p >{{ scope.row.belongWorkID }}</p>
              </template>
            </el-table-column>
            <el-table-column label="考勤工时(天)" prop="workCount" >
              <template #default="scope">
                <p >{{ scope.row.workCount }}</p>
              </template>
            </el-table-column>
            <el-table-column label="考勤工资(元)" prop="workPay" >
              <template #default="scope">
                <p >{{ scope.row.workPay }}</p>
              </template>
            </el-table-column>
            <el-table-column label="加班工时(h)" prop="overCount" >
              <template #default="scope">
                <p >{{ scope.row.overCount }}</p>
              </template>
            </el-table-column>
            <el-table-column label="加班工资(元)" prop="overPay" >
              <template #default="scope">
                <p >{{ scope.row.overPay }}</p>
              </template>
            </el-table-column>
            <el-table-column label="总金额(元)" prop="allPay" >
              <template #default="scope">
                <p >{{ scope.row.allPay }}</p>
              </template>
            </el-table-column>
            <el-table-column label="收款账号" prop="bankCard" >
              <template #default="scope">
                <p >{{ scope.row.userInfo.bankCard }}</p>
              </template>
            </el-table-column>


            <!--            <el-table-column label="状态" prop="state" >
                          <template #default="scope">
                            <p >{{ 0==state ? '待确认' : '已确认'}}</p>
                          </template>
                        </el-table-column>-->

            <el-table-column label="操作" width="120">
              <template #default="scope">
                <div>
                  <!--                  <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">待确认</el-button>-->
<!--                  <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px"  @click="editPaysheet(scope.row)">{{ 0==scope.row.state ? '待确认' : '已确认'}}</el-button>-->
                  <!--                  <el-button type="text"  style="padding-top: 5px; padding-bottom: 5px; min-height: 26px; color: #F56C6C"  @click="removeWorker(scope.row)">移除</el-button>-->
                </div>
              </template>
            </el-table-column>


          </el-table>
          <div class="pagination" style="width: 100%">
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[8, 12, 20, 40, 100]"
                :current-page="pageNo"
                :page-size="pageSize"
                :total="count"
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>

      </el-main>


    </el-container>
    <WorkstatEditDialog :visible="paysheetDialogVis" @cancel="paysheetDialogVis = false" :form="paysheetForm" @submit="paysheetSubmit"></WorkstatEditDialog>

    <el-dialog title="选择月份" v-model="monthFormVisible" @close="monthFormVisible=false">
      <el-form :model="monthform">
        <div class="h-box">
          <div class="v-box" style="margin-bottom: 8px">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>选择月份:</div>

            <el-date-picker v-model="monthform.month"
                type="month" placeholder="选择月份" style="width: 150px; margin-left: 16px" />
          </div>

        </div>
      </el-form>
      <template #footer>
            <span class="dialog-footer">
                <el-button @click="monthFormVisible=false">取 消</el-button>
                <el-button type="primary" @click="monthFormIndex == 1 ? reloadPaysheetMonth(monthform): exportExcelMonth(monthform)">确 定</el-button>
             </span>
      </template>
    </el-dialog>

  </el-container>
</template>

<script>
import WorkstatEditDialog from "./WorkstatEditDialog";
import {formatDate} from '../js/formatDate.js';
import {export_json_to_excel, export_json_to_excel2} from "@/vendor/Export2Excel";
export default {
  name: "PaysheetManage",
  components: {WorkstatEditDialog},
  data () {
    return {
      coverSrc:require("../../assets/img/zan_wu.png"),
      paysheetList:[],
      landList:[],
      searchForm:{
        month:new Date(),
      },
      paysheetDialogVis:false,
      paysheettForm:{
        formType: 0,   //0新增 1编辑
        workName:'',

        belongWorkID: 0,
        dateTime: 0,

        workType: 'NULL',
        overDuration:0,
        workDuration:0,
      },
      pageNo: 1,
      pageSize: 8,
      count:0,
      monthform:{
        month:'',
      },
      monthFormVisible: false,
      monthFormIndex: 0,  //0：导出工资单  1：重新生成工资单
    }
  },
  mounted(){
    this.getList();

  },
  methods:{

    myFormatDate(time) {
      if (!time) return "-";
      let date = new Date(time);
      let str = formatDate(date, 'hh:mm:ss');
      return str;
    },
    myFormatDate2(time) {
      if (!time) return "-";
      let date = new Date(time);
      let str = formatDate(date, 'yyyy-MM');
      return str;
    },
    getHourStr(m) {
      if (!m) return "-";
      let hour = (m/60).toFixed(1);
      return hour+"小时";
    },
    searchFormChange(){
      this.pageNo = 1;
      this.getList();
    },
    getList(){
      this.apiUtils.syPaysheetListGet({pageSize:this.pageSize, pageNo:this.pageNo,
        month: this.searchForm.month ? this.searchForm.month.getTime():null}).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.count = res.data.count
          this.paysheetList = res.data.list
        } else {
          this.$message.error("获取工资单失败! " + res.message);
          console.log(res);
        }

      });
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pageSize = size;
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.pageNo = val
      this.getList();
    },
    editPaysheet(row){
      this.paysheetForm = {
        formType: 1,   //0新增 1编辑

        workName:row.workName,

        belongWorkID: row.belongWorkID,
        dateTime: row.dateTime,

        workType: row.workType,
        overDuration: row.overDuration,
        workDuration: row.workDuration
      }
      this.paysheetDialogVis = true;
    },
    removePaysheet(row){
      this.$confirm('此操作将移除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {

            this.$message.info("该功能尚在建设中! ");

          })
          .catch(() => {

          })
    },
    paysheetSubmit(form){
      console.error(form)
      this.apiUtils.syPaysheetEdit({

        belongWorkID: form.belongWorkID,
        dateTime: form.dateTime,

        workType: form.workType,
        overDuration: form.overDuration,
        workDuration: form.workDuration,
      }).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.paysheetDialogVis = false;
          this.getList();
        } else {
          this.$message.error("编辑工资信息失败! " + res.message);
          console.log(res);
        }

      });

    },

    reloadPaysheet(){
      this.monthFormIndex = 1
      this.monthFormVisible = true
    },
    reloadPaysheetMonth(mf){
      if (!mf.month || mf.month.getTime()<1000){
        this.$message.error("请先选择月份！ ");
        return
      }
      this.apiUtils.syPaysheetSometimeReload({ dateTime: mf.month.getTime()}).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.pageNo = 1;
          this.getList();
        } else {
          this.$message.error("刷新工资单失败! " + res.message);
          console.log(res);
        }

      });
    },

    exportExcel(){
      this.monthFormIndex = 0
      this.monthFormVisible = true
    },
    exportExcelMonth(mf){
      if (!mf.month || mf.month.getTime()<1000){
        this.$message.error("请先选择月份！ ");
        return
      }
      console.error(mf.month.getTime())
      this.apiUtils.syPaysheetSometimeGet({ month: mf.month.getTime()}).then(res => {
        if (res.code == 200){
          console.log(res.data);
          let list = res.data
          this.downExcel(list, mf.month)
        } else {
          this.$message.error("获取工资单失败! " + res.message);
          console.log(res);
        }

      });
    },

    downExcel(list, time){
      this.createExcel(list,
          formatDate(time, 'yyyy年MM月')
          + "_工资单",
      );
    },
    createExcel(dataList, name) {
      require.ensure([], () => {
        const {export_json_to_excel} = require('../../vendor/Export2Excel');
        const tHeader = ['收款账户类型（0：个人 1：企业）*', '收款账号*', '收款账号户名*', '付款金额*', '付款用途*', '企业内部指令编号', '付款人备注'];
        const filterVal = ['type', 'bankCard', 'name', 'allPay', 'useType', 'companyID', 'remarks'];
        const list = dataList;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel2(tHeader, data, name);
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          if (j == 'type') return '0';
          if (j == 'bankCard') return v.userInfo.bankCard;
          if (j == 'name') return v.userInfo.name;
          if (j == 'allPay') return v.allPay;
          if (j == 'useType') return '工资/奖金';
          if (j == 'companyID') return '';
          if (j == 'remarks') return '';
          return v[j]
        })
      })
    },
  }
}
</script>

<style scoped>
/deep/ .el-table td, /deep/ .el-table th{
  padding: 6px 0;
  font-size: 13px;
}

/deep/ .el-table .cell{
  padding-left: 5px;
  padding-right: 5px;
}
</style>