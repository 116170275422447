<template>
  <div class="content v-box" style="flex-wrap: wrap;" >
    <el-card :body-style="{ padding: '0px', margin: '8px',}"  v-for="item in abilityList" style="margin: 8px; height: fit-content;"
             :key="item.id"
             :label="item.abilityName"
             :value="item.id">
      <div style="padding: 14px;">
        <div class="bottom">
          <el-button type="text" class="button" @click="toAbility(item)">{{ item.abilityName }}</el-button>
        </div>
      </div>
    </el-card>
    <el-table
        :data="deviceList"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"

        :show-header="false"
    >
      <el-table-column type="index" width="50">
      </el-table-column>
      <el-table-column label="图标" prop="name" width="100">
        <template #default="scope">
          <el-avatar shape="square" :size="30" :src="'./icon/'+scope.row.icon+'.png'" @error="true" style="background-color: #ffffff">
            <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
          </el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="设备组" prop="name" width="280">
        <template #default="scope">
          <p class="nameHint">{{ scope.row.name }}</p>
          <p class="snHint">{{ scope.row.id }}</p>
        </template>
      </el-table-column>
      <el-table-column label="协议类型" prop="type" >
        <template #default="scope">
          <div style="float: left" @click="beforeChange(scope.row)">
            <el-switch v-if="scope.row.type == 'RELAY'"
                       v-model="scope.row.lastValue" active-value="ON" inactive-value="OFF"
                       :loading="scope.row.isLoading != null && scope.row.isLoading"></el-switch>
          </div>
          <p class="nameHint" v-show="scope.row.type != 'RELAY'">{{ scope.row.lastValue + scope.row.unit }}</p>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="online" >
        <template #default="scope">
          <p :class="[scope.row.online?'blue':'grey']" style="white-space: pre-line;">
            {{ (scope.row.online  ?'在线\n':'离线\n')+ myTimeUpdate(scope.row.updateTime) }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <div>
            <el-tag
                type="success"
                @click="toInfo2(scope.row)"
            >历史记录</el-tag>

            <el-tag style="margin-left: 10px"
                    type="success"
                    @click="toInfo3(scope.row)"
            >统计记录</el-tag>
          </div>
          <div style="margin-top: 10px" v-show="scope.row.type == 'RELAY'">
            <el-tag
                type="info"
                @click="timingTaskDialog(scope.row)"
            >定时任务</el-tag>

          </div>

          <!-- <el-button
                   type="text"
                   icon="el-icon-delete"
                   class="red"
                   @click="handleDelete(scope.$index, scope.row)"
           >删除</el-button>-->
        </template>
      </el-table-column>


    </el-table>
  </div>
</template>

<script>
import {timeUpdate} from "@/views/js/formatDate";

let that
export default {
  name: "DeviceGroupInfo",
  mounted(){
    that = this;
    this.getList();
    this.getAbility();
  },
  data(){
    return{
      sn:this.$route.params.sn,
      deviceList:[],
      abilityList:[],
    }
  },
  methods:{
    myTimeUpdate(time) {
      return timeUpdate(time);
    },
    getList(){
      this.apiUtils.syDeviceList({sn:this.sn}).then(res => {
        if (res.code == 200){
          this.deviceList = res.data
        } else {
          this.$message.error("获取设备列表失败! " + res.errInfo);
          console.log(res);
        }

      });
    },
    getAbility(){
      this.apiUtils.syGroupAbilityList({sn:this.sn}).then(res => {
        if (res.code == 200){
          this.abilityList = res.data
        } else {
          this.$message.error("获取能力列表失败! " + res.errInfo);
          console.log(res);
        }

      });
    },
    toAbility(row){
      console.log(row)

      let abilityType = row.abilityType;  //radarmap、trackmap

      let path = '/'+abilityType+'/'+row.deviceGroupSN;
      let list = this.$store.state.tagsList
      let isExist = list.some(item => {
        return item.path === path;
      });
      let name = row.abilityName == null ? abilityType : row.abilityName
      if (!isExist) {
        if (list.length >= 8) {
          this.$store.commit("delTagsItem", {index: 0});
        }
        this.$store.commit("setTagsItem", {
          name: name,
          title: name,
          path: path
        });
      }
      this.$router.push(path)

    },
    toInfo2(row){
      console.error(row)
      this.layer.open({
        type: 2,
        title: row.name + ' 历史记录',
        shadeClose: true,
        shade: false,
        maxmin: true, //开启最大化最小化按钮
        area: ['1093px', '600px'],
        content: '/#/history/'+row.id,
        resize: true,
        resizing: function(layero, index){
          console.log(">>>>>>>>>>>>?////////////////////////",layero);
          //  document.getElementsByClassName('layui-layer-content')[0].style.height = '100%'

        },
        full:function (layero, index) {
          /*this.layer.style(index, {
              top:0,
              width: '100%',
              height: '100%'
          });*/
        },
        success: function(layero, index) {
          document.getElementById('layui-layer'+index).style.zIndex = 99999;
          document.getElementById('layui-layer'+index).style.minWidth = "700px";
          document.getElementById('layui-layer'+index).style.minHeight = "500px";
          document.getElementById('layui-layer'+index).addEventListener("mousedown", () => {
            document.getElementById('layui-layer'+index).style.zIndex = 99999;
            if(that.iframeIndex != index){
              try {
                document.getElementById('layui-layer' + that.iframeIndex).style.zIndex = 9999;
              } catch (e) {
              }
            }
            that.iframeIndex = index;
          })

          if(that.iframeIndex != index){
            try {
              document.getElementById('layui-layer' + that.iframeIndex).style.zIndex = 9999;
            } catch (e) {
            }
          }
          that.iframeIndex = index;
        }
      });
    },
    toInfo3(row){
      this.layer.open({
        type: 2,
        title: row.name + ' 历史记录',
        shadeClose: true,
        shade: false,
        maxmin: true, //开启最大化最小化按钮
        area: ['1093px', '600px'],
        content: (row.type == 'RELAY' ? '/#/relay/history/' : '/#/sensor/history/')+row.id,
        resize: true,
        full:function (layero, index) {

        },
        success: function(layero, index) {
          document.getElementById('layui-layer'+index).style.zIndex = 99999;
          document.getElementById('layui-layer'+index).style.minWidth = "960px";
          document.getElementById('layui-layer'+index).style.minHeight = "500px";
          document.getElementById('layui-layer'+index).addEventListener("mousedown", () => {
            document.getElementById('layui-layer'+index).style.zIndex = 99999;
            if(that.iframeIndex != index){
              try {
                document.getElementById('layui-layer' + that.iframeIndex).style.zIndex = 9999;
              } catch (e) {
              }
            }

            that.iframeIndex = index;
          })
          if(that.iframeIndex != index){
            try {
              document.getElementById('layui-layer' + that.iframeIndex).style.zIndex = 9999;
            } catch (e) {
            }
          }
          that.iframeIndex = index;
        }
      });
    },
  }
}
</script>

<style scoped>

</style>