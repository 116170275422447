<template>
    <el-container style="height: 100%; ">

        <el-container>
           <!-- <el-header style="text-align: right; font-size: 12px; height: auto; padding: 0px 10px 10px 20px">
                <div class="v-box" style="padding: 8px; background-color: #fff; border-radius: 5px;">
                    <img :src="coverSrc" class="cover-img" />
                </div>
                <div class="v-box" style="margin: 8px 0 0 0">
                    <el-input v-model="searchForm.name" placeholder="请输入计划名称" style="width: 200px; margin-right: 16px"></el-input>
                    <el-input v-model="searchForm.name" placeholder="请输入批次号" style="width: 200px; margin-right: 16px"></el-input>
                    <el-input v-model="searchForm.name" placeholder="请输入负责人" style="width: 200px; margin-right: 16px"></el-input>
                    <el-select v-model="searchForm.state" placeholder="请选择状态" style="width: 200px; margin-right: 16px">
                        <el-option key="-1" label="全部" value="-1"/>
                        <el-option key="0" label="实施中" value="0"/>
                        <el-option key="1" label="已结束" value="1"/>
                    </el-select>
                    <el-button size="medium" style="margin: 0 8px 0 0">重置</el-button>
                    <el-button size="medium" style="margin: 0 8px 0 0">搜索</el-button>
                    <div style="flex: 1">
                        <el-button size="medium" type="primary" style="margin: 0 0 0 0; float: right">新增</el-button>
                    </div>
                </div>
            </el-header>-->

            <el-main style="padding: 0px 10px 10px 20px">
                <div class="container" >
                    <el-table
                            :data="cameraList"
                            class="table"
                            ref="multipleTable"
                            header-cell-class-name="table-header"
                            @selection-change="handleSelectionChange"
                            :show-header="true"
                    >
                        <el-table-column label="序号" type="index" width="50">
                        </el-table-column>
                        <el-table-column label="名称" prop="name">
                            <template #default="scope">
                                <p class="nameHint">{{ scope.row.name }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="序列号" prop="sn" >
                            <template #default="scope">
                                <p >{{ scope.row.sn }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="类型" prop="sourcePlatform" >
                            <template #default="scope">
                                <p >{{ scope.row.sourcePlatform }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="品牌" prop="brand" >
                            <template #default="scope">
                                <p >{{ scope.row.brand }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="型号" prop="model" >
                            <template #default="scope">
                                <p >{{ scope.row.model }}</p>
                            </template>
                        </el-table-column>

                        <el-table-column label="状态" prop="state" >
                            <template #default="scope">
                                <p >{{ scope.row.state == 0 ? '离线' : scope.row.state == 1 ? '在线' : '未知'}}</p>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" width="120">
                            <template #default="scope">
                                <div>
                                    <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px" @click="openCameraImage(scope.row)">查看抓图</el-button>
                                    <el-button v-show="scope.row.hlsUrl" type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">实时监控</el-button>
                                </div>
                            </template>
                        </el-table-column>


                    </el-table>

                </div>
            </el-main>


        </el-container>

    </el-container>
</template>

<script>
    export default {
        name: "CameraManage",
        components: {},
        data () {
            return {
                coverSrc:require("../../assets/img/zan_wu.png"),
                cameraList:[],
                searchForm:{
                    id:null,
                    name:null,
                    director:null,
                    state:'-1'
                },
            }
        },
        mounted(){
            this.getList();
        },
        methods:{
            getList(){
                this.apiUtils.cameraListGet({}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.cameraList = res.data.list
                    } else {
                        this.$message.error("获取监控列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            openCameraImage(row){
                let path = '/cameraimage/'+row.sn;
                let list = this.$store.state.tagsList
                let isExist = list.some(item => {
                    return item.path === path;
                });
                if (!isExist) {
                    if (list.length >= 8) {
                        this.$store.commit("delTagsItem", { index: 0 });
                    }
                    this.$store.commit("setTagsItem", {
                        name: row.name,
                        title: row.name,
                        path: path
                    });
                }

                this.$router.push(path)
            },
        }
    }
</script>

<style scoped>
    /deep/ .el-table td, /deep/ .el-table th{
        padding: 6px 0;
        font-size: 13px;
    }

    /deep/ .el-table .cell{
        padding-left: 5px;
        padding-right: 5px;
    }
</style>