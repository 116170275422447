<template>
  <el-dialog title="编辑人员信息" v-model="dialogFormVisible" @close="$emit('cancel')">
    <el-form :model="myform">
      <div class="h-box">
        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>员工姓名:</div>
          <el-input v-model="myform.name"  style="width: 150px; margin-left: 16px"></el-input>

          <div v-show="myform.formType==1" style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red"></span>员工编号:</div>
          <span v-show="myform.formType==1" style="width: 150px; margin-left: 16px">{{myform.workID}}</span>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>员工性别:</div>
          <el-select v-model="myform.sex" placeholder="请选择性别" style="width: 150px; margin-left: 16px">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>手机号码:</div>
          <el-input v-model="myform.phone"  style="width: 150px; margin-left: 16px"></el-input>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>身份证号码:</div>
          <el-input v-model="myform.idCard"  style="width: 150px; margin-left: 16px"></el-input>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>银行卡号码:</div>
          <el-input v-model="myform.bankCard"  style="width: 150px; margin-left: 16px"></el-input>
        </div>

      </div>
    </el-form>
    <template #footer>
            <span class="dialog-footer">
                <el-button @click="$emit('cancel')">取 消</el-button>
                <el-button type="primary" @click="submit(myform)">确 定</el-button>
             </span>
    </template>
  </el-dialog>
</template>

<script>
import {computed} from 'vue'
import VueCronLinux from "./VueCronLinux";
export default {
  name: "WorkerEditDialog",
  components: {VueCronLinux},
  props:{
    visible:{type:Boolean, default:false},
    lands:{type:Array, default:[]},
    form: {
      type:Object,
      default(){
        return{
          formType: 0,   //0新增 1编辑

          username: '',
          deptID: '',
          deptName:'',
          roleID:'',
          roleName:'',
          permission:'',

          name:'',
          workID:0,
          sex:'男',
          phone:'',
          idCard:'',
          bankCard:'',
          createTime:'',
        }
      }
    },
  },
  setup(props){//提供的函数props直接用就完事了嗷~~~

  },
  data() {
    return {
      dialogFormVisible: this.visible,
      myform: this.form,
      directorList:[],
      formLabelWidth: '120px'
    };
  },
  watch: {
    form(newV, oldV) {
      this.myform = newV
    },
    visible(newV, oldV) {
      this.dialogFormVisible = newV
    },
  },
  methods:{
    submit(myf){
      if (myf.name == null || myf.name.length<1){
        this.$message.error("请填写员工姓名!" );
        return
      }
      if (myf.sex == null || myf.sex.length<1){
        this.$message.error("请选择员工性别!" );
        return
      }
      if (myf.phone == null || myf.phone.length<1){
        this.$message.error("请填写手机号码!" );
        return
      }

      if (myf.idCard == null || myf.idCard.length<1){
        this.$message.error("请填写身份证号码!" );
        return
      }
      if (myf.bankCard == null || myf.bankCard.length<1){
        this.$message.error("请填写银行卡号!" );
        return
      }

      this.$emit('submit',myf)
    }
  }
}
</script>

<style scoped>
* {
  margin: auto 0;
}
/deep/ .el-date-editor--daterange {
  margin: auto 0;
}
/deep/ .select-trigger {
  margin: auto 0;
}
/deep/ .el-range-editor.el-input__inner{
  padding-top: 0;
  padding-bottom: 0;
}
/deep/ .el-input__suffix {
  line-height: 30px;
}
/deep/ .el-input__inner{
  height: 30px;
  line-height: 30px;
}
/deep/ .el-input__icon{
  line-height: 30px;
}
</style>