<template>

    <el-container style="height: 100%; ">
        <el-aside width="300px" style="background-color: #fff; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
            <el-container style="height: 100%; ">
                <el-header style="text-align: right; font-size: 12px; background-color: #f6f7fb; height: auto">
                    <el-tabs tab-position="bottom" v-model="message" :stretch="true">
                        <el-tab-pane label="所有设备" name="first" ></el-tab-pane>
                        <el-tab-pane label="报警" name="second"></el-tab-pane>
                        <el-tab-pane label="离线" name="third"></el-tab-pane>
                    </el-tabs>
                </el-header>
                <el-main style="padding: 0" height="100%" >
                    <div v-for="(item, i) in deviceGroupList">
                        <div :class="currentRowInfo.id == item.id ? 'selectedRow':''"
                             @click="selectBaseRow(item)">
                            <el-row
                                    type="flex" justify="left" style="height: 60px; padding: 5px">
                                <el-col :span="21">
                                    <p class="nameHint">{{ item.name }}</p>
                                    <p class="snHint">{{ item.id }}</p>
                                </el-col>
                                <el-col :span="3">
                                    <i :class="expandShowMap.has(item.id) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                                       @click.stop=" expandShowMap.has(item.id) ? expandShowMap.delete(item.id) : expandShowMap.add(item.id)"
                                       style="font-size: 25px; height: 50px; line-height: 50px; color: #97a8be"></i>
                                </el-col>
                            </el-row>
                            <div class="divider"></div>
                        </div>
                        <ul v-show="expandShowMap.has(item.id)">
                            <div v-show="!item.landList || item.landList.length == 0" style="margin-left: 16px;">
                                <p style="height: 40px; line-height: 40px; color: #b5b5b5; font-size: 14px; margin-left: 8px">无地块</p>
                                <div class="divider"></div>
                            </div>
                            <li v-for="(sonItem, j) in item.landList" style="padding-left: 16px; list-style-type:none"
                                @click="selectLandRow(item, sonItem)"
                                :class="currentRowInfo.id == sonItem.id ? 'selectedRow':''">
                                <el-row type="flex" justify="left" style="height: 60px; padding: 5px">
                                    <el-col :span="24">
                                        <p class="nameHint">{{ sonItem.name }}</p>
                                        <p class="snHint">{{ sonItem.id }}</p>
                                    </el-col>
                                </el-row>
                                <div class="divider"></div>
                            </li>
                        </ul>
                    </div>

                   <!-- <el-table
                            height="100%"
                            :data="deviceGroupList"
                            border
                            class="table"
                            ref="singleTable"
                            header-cell-class-name="table-header"
                            @selection-change="handleSelectionChange"
                            @row-click="toInfo"
                            highlight-current-row
                            @current-change="handleCurrentChange"
                            :tree-props="{children: 'landList', hasChildren: 'hasChildren'}"
                            :show-header="false"
                    >
                        <el-table-column type="expand">
                            <template #default="scope">
                                <el-table
                                        height="100%"
                                        :data="scope.row.landList"
                                        border
                                        class="table"
                                        ref="singleTable"
                                        header-cell-class-name="table-header"
                                        highlight-current-row
                                        :show-header="false"
                                >
                                    <el-table-column  prop="name">
                                        <template #default="scope">
                                            <el-row type="flex" justify="left">
                                                <el-col :span="20">
                                                    <p class="nameHint">{{ scope.row.name }}</p>
                                                    <p class="snHint">{{ scope.row.id }}</p>
                                                </el-col>
                                                <el-col :span="4">
                                                    <p :class="[scope.row.online?'blue':'grey']" style="line-height: 60px; font-size: 12px">
                                                        {{ scope.row.online?'在线':'离线'}}
                                                    </p>
                                                </el-col>
                                            </el-row>


                                        </template>
                                    </el-table-column>

                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column  prop="name">
                            <template #default="scope">
                                <el-row type="flex" justify="left">
                                    <el-col :span="20">
                                        <p class="nameHint">{{ scope.row.name }}</p>
                                        <p class="snHint">{{ scope.row.id }}</p>
                                    </el-col>
                                    <el-col :span="4">
                                        <p :class="[scope.row.online?'blue':'grey']" style="line-height: 60px; font-size: 12px">
                                            {{ scope.row.online?'在线':'离线'}}
                                        </p>
                                    </el-col>
                                </el-row>


                            </template>
                        </el-table-column>

                    </el-table>-->
                </el-main>
                <div style="background-color: #c2c2c2; width: 100%; height: 1px"></div>
                <el-footer style="text-align: center; line-height: 60px; background-color: #fff; padding: 0">

                    <el-row type="flex" class="row-bg" justify="center">
                        <el-col :span="11"><el-button type="text" style="width: 100%; height: 100%">设备组管理</el-button></el-col>
                        <div style="background-color: #c2c2c2; width: 1px; height: 30px;margin-top: 15px"></div>
                        <el-col :span="11"><el-button type="text" style="width: 100%; height: 100%">新建设备组</el-button></el-col>
                    </el-row>
                </el-footer>
            </el-container>

        </el-aside>

        <el-container>
            <el-header style="text-align: right; font-size: 12px; height: auto; padding: 0px 10px 10px 20px">
                <div class="v-box" style="padding: 8px; background-color: #fff; border-radius: 5px;">
                    <img :src="coverSrc" class="cover-img" />
                    <div class="h-box" style="width: 100%; margin-left: 8px; justify-content: space-between">
                        <div class="v-box" style="width: 100%; align-items: baseline ;">
                            <span style="margin-right : auto; font-size: 16px">{{currentRowInfo.name}}</span>
                            <span v-show="false">禁用</span>
                        </div>
                        <div class="v-box" style="width: 100%; align-items: baseline;">
                            <span style="margin-right : 20px; font-size: 14px; color: rgba(0,26,52,0.78)">联系人：{{currentRowInfo.contacts}}</span>
                            <span style="margin-right : auto; font-size: 14px; color: rgba(0,26,52,0.78)">联系电话：{{currentRowInfo.contactNumber}}</span>
                        </div>
                        <span style="margin-right: auto; font-size: 14px; color: rgba(0,26,52,0.78)">地址：{{currentRowInfo.address}}</span>
                        <span style="margin-right: auto;font-size: 14px; color: rgba(0,26,52,0.78)">面积：{{currentRowInfo.area}}亩</span>
                        <div class="v-box" style="width: 100%; align-items: baseline; justify-content:flex-end">
                            <span style="margin-right : auto; font-size: 12px; color: #97a8be">编号：{{currentRowInfo.id}}</span>
                            <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">查看</el-button>
                            <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">修改</el-button>
                            <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px" v-show="false">启用</el-button>
                        </div>

                    </div>

                </div>
            </el-header>

            <el-main style="padding: 0px 10px 10px 20px">
                <div class="container" >
                    <el-table
                            v-show="currentRowInfo.infoType == 'base'"
                            :data="landList"
                            class="table"
                            ref="multipleTable"
                            header-cell-class-name="table-header"
                            @selection-change="handleSelectionChange"
                            :show-header="true"
                    >
                        <el-table-column label="序号" type="index" width="50">
                        </el-table-column>
                        <el-table-column label="地块" prop="name">
                            <template #default="scope">
                                <p class="nameHint">{{ scope.row.name }}</p>
                                <p class="snHint">{{ scope.row.id }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="类型" prop="type" >
                            <template #default="scope">
                                <p >{{ scope.row.type }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="用途" prop="use" >
                            <template #default="scope">
                                <p >{{ scope.row.use }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="作物" prop="crop" >
                            <template #default="scope">
                                <p >{{ scope.row.crop }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="负责人" prop="admin" >
                            <template #default="scope">
                                <p >{{ scope.row.admin }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="180">
                            <template #default="scope">
                                <div>
                                    <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">查看</el-button>
                                    <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">修改</el-button>
                                    <!--<el-tag
                                            type="success"
                                            @click="toInfo2(scope.row)"
                                    >查看</el-tag>

                                    <el-tag style="margin-left: 10px"
                                            type="success"
                                            @click="toInfo3(scope.row)"
                                    >修改</el-tag>-->
                                </div>
                            </template>
                        </el-table-column>


                    </el-table>

                    <el-table
                            v-show="currentRowInfo.infoType == 'land'"
                            :data="deviceList"
                            class="table"
                            ref="multipleTable"
                            header-cell-class-name="table-header"
                            @selection-change="handleSelectionChange"
                            :show-header="true"
                    >
                        <el-table-column type="expand">
                            <template #default="scope">
                                <div class="sensor" style="background-color: #f0f0f0">
                                    <el-table
                                            style="margin-left: 100px;"
                                            :data="scope.row.deviceList"
                                            class="table"
                                            ref="multipleTable"
                                            header-cell-class-name="table-header"
                                            :show-header="false"
                                    >
                                        <el-table-column type="index" width="50">
                                        </el-table-column>
                                        <el-table-column label="图标" prop="name" width="100">
                                            <template #default="scope">
                                                <el-avatar shape="square" :size="30" :src="'./icon/'+scope.row.icon+'.png'" @error="true" style="background-color: #ffffff">
                                                    <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
                                                </el-avatar>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="设备组" prop="name" width="280">
                                            <template #default="scope">
                                                <p style="color: black; font-size: medium;margin: 0;">{{ scope.row.name }}</p>
                                                <p style="color: dimgray; font-size: small;margin: 0;">{{ scope.row.id }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="协议类型" prop="type" >
                                            <template #default="scope">
                                                <div style="float: left" @click="beforeChange(scope.row)">
                                                    <el-switch v-if="scope.row.type == 'RELAY'"
                                                               v-model="scope.row.lastValue" active-value="ON" inactive-value="OFF"
                                                               :loading="scope.row.isLoading != null && scope.row.isLoading"></el-switch>
                                                </div>
                                                <p style="color: black; font-size: medium;margin: 0;" v-show="scope.row.type != 'RELAY'">{{ scope.row.lastValue + scope.row.unit }}</p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="状态" prop="online" >
                                            <template #default="scope">
                                                <p :class="[scope.row.online?'blue':'grey']" style="white-space: pre-line;">
                                                    {{ (scope.row.online  ?'在线  ':'离线  ')+ myTimeUpdate(scope.row.updateTime) }}
                                                </p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="操作" width="180">
                                            <template #default="scope">
                                                <div>
                                                    <el-tag
                                                            type="success"
                                                            @click="toInfo2(scope.row)"
                                                    >历史记录</el-tag>

                                                    <el-tag style="margin-left: 10px"
                                                            type="success"
                                                            @click="toInfo3(scope.row)"
                                                    >统计记录</el-tag>
                                                </div>
                                                <div style="margin-top: 10px" v-show="scope.row.type == 'RELAY'">
                                                    <el-tag
                                                            type="info"
                                                            @click="timingTaskDialog(scope.row)"
                                                    >定时任务</el-tag>

                                                </div>

                                                <!-- <el-button
                                                         type="text"
                                                         icon="el-icon-delete"
                                                         class="red"
                                                         @click="handleDelete(scope.$index, scope.row)"
                                                 >删除</el-button>-->
                                            </template>
                                        </el-table-column>


                                    </el-table>
                                </div>

                            </template>
                        </el-table-column>

                        <el-table-column label="序号" type="index" width="50">
                        </el-table-column>
                        <!--<el-table-column label="图标" prop="name" width="100">
                            <template #default="scope">
                                <el-avatar shape="square" :size="50" :src="scope.row.icon"></el-avatar>
                            </template>
                        </el-table-column>-->
                        <el-table-column label="设备组" prop="name">
                            <template #default="scope">
                                <p class="nameHint">{{ scope.row.groupAlias ? scope.row.groupAlias : scope.row.deviceGroupName }}</p>
                                <p class="snHint">{{ scope.row.sn }}</p>
                            </template>
                        </el-table-column>
                       <!-- <el-table-column label="最新数据" prop="lastValue" >
                            <template #default="scope">
                                <el-switch v-if="scope.row.type == 'RELAY'"
                                           v-model="scope.row.lastValue" active-value="ON" inactive-value="OFF"
                                           :loading="scope.row.isLoading != null && scope.row.isLoading" @change="beforeChange(scope.row)" @click.stop=""></el-switch>
                                <p class="nameHint" v-show="scope.row.type != 'RELAY'">{{ scope.row.lastValue + scope.row.unit }}</p>

                               &lt;!&ndash; <p class="nameHint">
                                    {{ scope.row.lastValue +  scope.row.unit}}
                                </p>&ndash;&gt;
                            </template>
                        </el-table-column>-->

                        <el-table-column label="状态" prop="online" >
                            <template #default="scope">
                                <p :class="[scope.row.online?'blue':'grey']" style="white-space: pre-line;">
                                    {{ (scope.row.online  ?'在线\n':'离线\n')+ myFormatDate(scope.row.offlineTime) }}
                                </p>
                            </template>
                        </el-table-column>
                       <!-- <el-table-column label="操作" width="180">
                            <template #default="scope">
                                <div>
                                    <el-button @click="toInfo2(scope.row)" type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">历史记录</el-button>
                                    <el-button @click="toInfo3(scope.row)" type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">统计记录</el-button>
                                   &lt;!&ndash;
                                    <el-tag
                                            type="success"
                                            @click="toInfo2(scope.row)"
                                    >历史记录</el-tag>

                                    <el-tag style="margin-left: 10px"
                                            type="success"
                                            @click="toInfo3(scope.row)"
                                    >统计记录</el-tag>&ndash;&gt;
                                </div>
                                <div style="margin-top: 10px">
                                    <el-button @click="timingTaskDialog(scope.row)" type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">定时任务</el-button>
                                   &lt;!&ndash; <el-tag
                                            type="info"
                                            @click="timingTaskDialog(scope.row)"
                                    >定时任务</el-tag>&ndash;&gt;

                                </div>

                                &lt;!&ndash; <el-button
                                         type="text"
                                         icon="el-icon-delete"
                                         class="red"
                                         @click="handleDelete(scope.$index, scope.row)"
                                 >删除</el-button>&ndash;&gt;
                            </template>
                        </el-table-column>-->


                    </el-table>
                </div>
            </el-main>
        </el-container>
        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="名称">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="form.type" placeholder="请选择接入协议类型">
                        <el-option key="TCP" label="TCP协议" value="TCP"></el-option>
                        <el-option key="MQTT" label="MQTT协议" value="MQTT"></el-option>
                        <el-option key="MB_RTU" label="ModbusRTU协议" value="MB_RTU"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <TimingTaskDialog :visible="timingTaskDialogVis" @cancel="timingTaskDialogVis = false" @submit="timingTaskSubmit"></TimingTaskDialog>
    </el-container>


</template>

<script>
    import {formatDate, timeUpdate} from '../js/formatDate.js';
    import TimingTaskDialog from "./TimingTaskDialog";
    // import {  getDeviceGroupList, getDeviceList, getDeviceHistoryByID, controlRelay } from "../../api/index";
    let that
    export default {
        name: "BaseAndLandManage",
        components: {TimingTaskDialog},
        created(){
            that = this;
            try {
                this.handleDeviceList()
            } catch (e) {
                console.error(e)
            }
        },
        mounted(){
            try {
                let row = JSON.parse(localStorage.getItem("base_and_land_row"));
                if (row) {
                    this.currentRowInfo = {
                        infoType:  row.infoType,
                        id:  row.id,
                        base: row.base,
                        row: row.row
                    }
                    for (let key of row.expandShowMap) {
                        this.expandShowMap.add(key);
                    }
                }
            } catch (e) {
                console.error(e, localStorage.getItem("base_and_land_row"))
            }
        },
        beforeUnmount(){
            console.error(">>>>>>>>>>>>>/////")
            try {
                this.saveState();
            }catch (e) {
                console.error(e)
            }

        },
        setup(){

        },
        data () {
            return {
                expandShowMap: new Set(),
                coverSrc:require("../../assets/img/zan_wu.png"),
                message: 'first',
                multipleSelection: [],
                query: {
                    address: "",
                    name: "",
                    pageIndex: 1,
                    pageSize: 10
                },
                currentPage:1, //初始页
                pagesize:100,    //    每页的数据
                total:0,
                deviceList:[],
                deviceGroupList: [
                    /*{name:"test01", sn:"1001",type:"MB_RTU", online:0, offlineTime:1000, icon:null, createTime:0}*/
                ],
                editVisible: false,
                form:{

                },
                currentRow: null,
                landList:[],
                currentRowInfo:{
                    id:'xxx',
                    name:'XX',
                    contacts:'XX',
                    contactNumber:'XX',
                    address:'XX',
                    area:'XX',
                },
                iframeIndex:0,
                timingTaskDialogVis: false
            }
        },
        methods:{
            myFormatDate(time) {
                let date = new Date(time);
                return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
            },
            myTimeUpdate(time) {
                return timeUpdate(time);
            },
            addDeviceGroup(){
                this.form = {
                    name:"",
                    type:"TCP"
                };
                this.editVisible = true;

            },
            saveEdit(){

            },
            saveState(){
                localStorage.setItem("base_and_land_row", JSON.stringify({
                    infoType:  this.currentRowInfo.infoType,
                    id:  this.currentRowInfo.id,
                    base: this.currentRowInfo.base,
                    row: this.currentRowInfo.row,
                    expandShowMap: Array.from(this.expandShowMap)
                }));
            },
            hasBaseID(baseID){
                for (let deviceGroupListElement of this.deviceGroupList) {
                    if (deviceGroupListElement.id == baseID) return true;
                }
                return false;
            },

            hasLandID(baseID, landID){
                for (let deviceGroupListElement of this.deviceGroupList) {
                    if (deviceGroupListElement.id == baseID){
                        for (let landListElement of deviceGroupListElement.landList) {
                            if (landListElement.id == landID) return true;
                        }
                        return false;
                    }
                }
                return false;
            },
            initSelectRow(){
                if (this.deviceGroupList != null && this.deviceGroupList.length > 0){
                    if (this.currentRowInfo &&　this.currentRowInfo.row){
                        if (this.currentRowInfo.infoType == 'base'){
                            if (this.hasBaseID(this.currentRowInfo.row.id)){
                                this.selectBaseRow( this.currentRowInfo.row)
                            } else{
                                this.selectBaseRow( this.deviceGroupList[0])
                            }
                        }else{
                            if (this.hasLandID(this.currentRowInfo.base.id, this.currentRowInfo.row.id)){
                                this.selectLandRow( this.currentRowInfo.base, this.currentRowInfo.row)
                            }else{
                                this.selectBaseRow( this.deviceGroupList[0])
                            }
                        }
                    }
                } else{

                }
            },

            handleDeviceList() {
                this.apiUtils.baseAllGet({}).then(res => {
                    if (res.code == 200){
                        this.deviceGroupList = res.data
                        this.initSelectRow()
                    } else {
                        this.$message.error("获取设备组列表失败! " + res.message);
                        console.log(res);
                    }

                });
                /* this.$http.get('http://localhost:3000/userList').then(res => {  //这是从本地请求的数据接口，
                     this.userList = res.body
                 })*/
            },
            // 触发搜索按钮
            handleSearch() {


            },// 删除操作
            handleDelete(index) {
                // 二次确认删除
                this.$confirm("确定要删除吗？", "提示", {
                    type: "warning"
                })
                    .then(() => {
                        this.$message.success("删除成功");
                        //  this.tableData.splice(index, 1);
                    })
                    .catch(() => {});
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 编辑操作
            handleEdit(index, row) {

            },
            getGroupByLandID(landID){
                this.apiUtils.syGroupAndDeviceListByLandID({landID:landID}).then(res => {
                    if (res.code == 200){
                      //  this.deviceList = res.data
                        let list = [];
                        if (res.data) {
                            for (let element of res.data) {
                                list.push(element)
                            }
                        }
                        this.deviceList = list;
                    } else {
                        this.$message.error("获取设备列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            toInfo(row){
                this.apiUtils.getDeviceList({sn:row.sn}).then(res => {
                    if (res.code == 200){
                        this.deviceList = res.data
                    } else {
                        this.$message.error("获取设备列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            selectLandRow(baseRow, landRow){
                this.apiUtils.landInfoGet(landRow.id).then(res => {
                    if (res.code == 200){
                        this.currentRowInfo = res.data
                        this.currentRowInfo.base = baseRow
                        this.currentRowInfo.row = landRow
                        this.currentRowInfo.infoType = 'land'
                        this.landList = [];



                        this.saveState();

                    } else {
                        this.$message.error("获取地块详情失败! " + res.message);
                        console.log(res);
                    }

                });
                this.getGroupByLandID(landRow.id)
            },
            selectBaseRow(baseRow){
                this.apiUtils.baseInfoGet(baseRow.id).then(res => {
                    if (res.code == 200){
                        this.currentRowInfo = res.data
                        this.currentRowInfo.infoType = 'base'
                        this.currentRowInfo.row = baseRow
                        this.deviceList = null;
                        let list = [];
                        if (this.currentRowInfo.landList) {
                            for (let landListElement of this.currentRowInfo.landList) {
                                list.push(landListElement)
                            }
                        }
                        this.landList = list;

                        this.saveState();
                    } else {
                        this.$message.error("获取基地详情失败! " + res.message);
                        console.log(res);
                    }

                });
            },

            toInfo2(row){
                console.error(row)
                this.layer.open({
                    type: 2,
                    title: row.name + ' 历史记录',
                    shadeClose: true,
                    shade: false,
                    maxmin: true, //开启最大化最小化按钮
                    area: ['1093px', '600px'],
                    content: '/#/history/'+row.id,
                    resize: true,
                    resizing: function(layero, index){
                        console.log(">>>>>>>>>>>>?////////////////////////",layero);
                        //  document.getElementsByClassName('layui-layer-content')[0].style.height = '100%'

                    },
                    full:function (layero, index) {
                        /*this.layer.style(index, {
                            top:0,
                            width: '100%',
                            height: '100%'
                        });*/
                    },
                    success: function(layero, index) {
                        document.getElementById('layui-layer'+index).style.zIndex = 99999;
                        document.getElementById('layui-layer'+index).style.minWidth = "700px";
                        document.getElementById('layui-layer'+index).style.minHeight = "500px";
                        document.getElementById('layui-layer'+index).addEventListener("mousedown", () => {
                            document.getElementById('layui-layer'+index).style.zIndex = 99999;
                            if(that.iframeIndex != index){
                                try {
                                    document.getElementById('layui-layer' + that.iframeIndex).style.zIndex = 9999;
                                } catch (e) {
                                }
                            }
                            that.iframeIndex = index;
                        })

                        if(that.iframeIndex != index){
                            try {
                                document.getElementById('layui-layer' + that.iframeIndex).style.zIndex = 9999;
                            } catch (e) {
                            }
                        }
                        that.iframeIndex = index;
                    }
                });
            },
            toInfo3(row){
                this.layer.open({
                    type: 2,
                    title: row.name + ' 历史记录',
                    shadeClose: true,
                    shade: false,
                    maxmin: true, //开启最大化最小化按钮
                    area: ['1093px', '600px'],
                    content: (row.type == 'RELAY' ? '/#/relay/history/' : '/#/sensor/history/')+row.id,
                    resize: true,
                    full:function (layero, index) {

                    },
                    success: function(layero, index) {
                        document.getElementById('layui-layer'+index).style.zIndex = 99999;
                        document.getElementById('layui-layer'+index).style.minWidth = "960px";
                        document.getElementById('layui-layer'+index).style.minHeight = "500px";
                        document.getElementById('layui-layer'+index).addEventListener("mousedown", () => {
                            document.getElementById('layui-layer'+index).style.zIndex = 99999;
                            if(that.iframeIndex != index){
                                try {
                                    document.getElementById('layui-layer' + that.iframeIndex).style.zIndex = 9999;
                                } catch (e) {
                                }
                            }

                            that.iframeIndex = index;
                        })
                        if(that.iframeIndex != index){
                            try {
                                document.getElementById('layui-layer' + that.iframeIndex).style.zIndex = 9999;
                            } catch (e) {
                            }
                        }
                        that.iframeIndex = index;
                    }
                });
            },
            timingTaskDialog(row){
                this.timingTaskDialogVis = true
                console.error(">>>>>>>  ", this.timingTaskDialogVis)
            },
            timingTaskSubmit(form){
                console.error(form)
                this.timingTaskDialogVis = false




            },
            beforeChange(row){
                console.log(row)
                let state = row.lastValue;
                row.isLoading = true

                this.apiUtils.syControlRelay({sn:row.belongDeviceGroupSN, deviceId:row.deviceID, action:state}).then(res => {
                    if (res.code == 200){
                        let resule = res.data
                        console.log(resule)
                        if (resule!=null && resule.resultCode == 200) {
                            row.lastValue = resule.setValue;
                        }else{
                            row.lastValue = state == 'ON'?'OFF':'ON'
                            this.$message.error("控制失败! " + resule.msg);
                            console.log(res);
                        }
                    } else {
                        row.lastValue = state == 'ON'?'OFF':'ON'
                        this.$message.error("控制命令发送失败! " + res.message);
                        console.log(res);
                    }

                }).finally(()=>{ row.isLoading = false});

                /* setTimeout(() => {
                     row.isLoading = false
                     row.lastValue = state == 'ON'?'OFF':'ON'
                 }, 1000)*/
                /* return new Promise((resolve , reject)=> {
                     setTimeout(() => {
                         row.isLoading = false
                         return reject(new Error('error'))
                     }, 1000)
                 })*/

            }
        },
    }
</script>
<style>
    /*  .layui-layer-iframe  {
          min-width: 700px !important;
          min-height: 500px !important;
          z-index:9999;
      }*/
    .layui-layer-content  {
        height: calc(100% - 42px) !important;
    }
    .layui-layer-content>iframe{
        height: 100% !important;
    }
</style>
<style scoped>
    .sensor /deep/ .el-table__cell{
        padding: 8px 0;
    }
    .sensor /deep/ .el-table{
        padding: 0px 0;
        width: calc(100% - 100px);
    }
    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand /deep/ label {
        width: 100px;
        color: #99a9bf;
    }
    .demo-table-expand /deep/ .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }


    /deep/ .el-table__empty-block{
        width: 100% !important;
    }

    .cover-img {
        width: 120px;
        height: 120px;
        background: #f8f8f8;
        border: 1px solid #eee;
        border-radius: 5px;
    }
    .selectedRow{
        background-color: rgba(186, 203, 225, 0.29);
    }
    .el-tabs__header  {
        margin: 0 0 0 !important;
    }
    .divider {
        width: calc(100% - 16px);
        height: 1px;
        background-color: rgba(186, 203, 225, 0.29);
        margin: 0 8px 0 8px;
    }
    .nameHint {
        color: black;
        font-size: 16px;
        margin: 3px 0 0 3px;
    }
    .snHint {
        color: #b5b5b5;
        font-size: 12px;
        margin: 2px 0 3px 3px;
    }
    .hint {
        color: black;
        line-height: 30px;
        margin: 0;
    }
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .table {
        width: 100%;
        font-size: 14px;
    }
    .red {
        color: #ff0000;
    }
    .mr10 {
        margin-right: 10px;
    }
    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }


</style>