<template>
    <div class="v-box" style="flex-wrap: wrap">
        <div class="v-box" style="width: 100%; padding: 8px; margin-left:8px; margin-right:8px;background-color: #fff; border-radius: 5px;">
            <div class="h-box" style="width: 100%; margin-left: 8px; justify-content: space-between">
                <div class="v-box" style="width: 100%; align-items: baseline ;">
                    <span style="margin-right : auto; font-size: 16px">{{info.name}}</span>
                    <span style="font-size: 12px; color: #97a8be">SN：{{info.sn}}</span>
                </div>
                <div style="background-color: #c2c2c2; width: 100%; height: 1px; margin: 16px 0"></div>
                <div class="h-box" style="width: 100%; align-items: baseline; justify-content:flex-end">

                    <div style="margin: 0 0 8px 0">
                        <div style="margin-right : auto; font-size: 14px; color: rgba(0,26,52,0.78); width: 80px;  float: left; line-height: 40px">访 问 码：</div>
                        <el-input v-model="info.sharePassword" :disabled="info.share==0" style="width: 130px; margin-right: 10px" ></el-input>
                        <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px" :disabled="info.share==0" @click="share(1)">设置访问码</el-button>
                    </div>
                    <div style="margin: 0 0 16px 0" v-show="info.shareCode">
                        <div style="margin-right : auto; font-size: 14px; color: rgba(0,26,52,0.78); width: 80px;  float: left">链    接：</div>
                        <span style="margin-right : auto; font-size: 14px; color: rgba(0,26,52,0.78)">{{'https://api.iezfarmer.com:37202/#/share/cameraimage/'+info.sn+'/'+info.shareCode}}</span>
                    </div>

                    <div>
                        <div style="margin-right : auto; font-size: 14px; color: rgba(0,26,52,0.78); width: 80px;  float: left">开启分享：</div>
                        <div style="float: left" @click="share(info.share)">
                            <el-switch v-model="info.share" :loading="info.isLoading != null && info.isLoading" :active-value="1" :inactive-value="0" ></el-switch>
                        </div>

                    </div>
                  <!--  <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px" @click="share">{{info.share==1?'关闭分享':info.shareCode?'打开分享':'生成分享链接'}}</el-button>-->
                    <!--<el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">修改</el-button>
                    <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px" v-show="false">启用</el-button>-->
                </div>

            </div>

        </div>

        <el-card :body-style="{ padding: '0px', margin: '8px'}"  v-for="item in cameraList" style="margin: 8px"
                 :key="item.id"
                 :label="item.name"
                 :value="item.id">
            <el-image @click="onPreview(item)" :src="item.type == 'IOT-CAMERA' ? 'https://api.iezfarmer.com/iotapi'+item.imageID
            : 'https://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID" style="height: 200px; width:auto; display: block; cursor: pointer" />
            <div style="padding: 14px;">
                <span>{{ myFormatDate(item.uploadTime) }}</span>
                <div class="bottom">
                   <!-- <time class="time">{{ myFormatDate(item.uploadTime) }}</time>-->
                    <el-button type="text" class="button" @click="onPreview(item)">查看大图</el-button>
                </div>
            </div>
        </el-card>
        <div class="pagination" style="width: 100%">
            <el-pagination
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[8, 12, 20, 40, 100]"
                    :current-page="pageNo"
                    :page-size="pageSize"
                    :total="count"
                    @size-change="handleSizeChange"
                    @current-change="handlePageChange"
            ></el-pagination>
        </div>
        <el-image-viewer
                v-if="showViewer"
                @close="closeViewer"
                @switch="swith"
                :infinite="false"
                :initial-index="imageIndex"
                :url-list="viewerList" />
    </div>
</template>

<script>
    import {formatDate} from '../js/formatDate.js';
    export default {
        name: "CameraImageMange",
        mounted(){
            this.getInfo();
            this.getList();
        },
        data(){
            return{
                id:this.$route.params.id,
                cameraList:[],
                currentSrc:'',
                showViewer: false,  //大图
                viewerList:[],
                idList:[],
                imageIndex:0,
                pageNo: 1,
                pageSize: 8,
                count:0,
                info:{},
            }
        },
        watch: {
            $route(newV, oldV) {
                this.id = newV.params.id;
                if (this.id){
                  this.getList();
                }

            },

        },
        methods:{
            myFormatDate(time) {
                let date = new Date(time);
                return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
            },
            getInfo(){
                this.apiUtils.cameraInfoGet({sn:this.id}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.info = res.data
                    } else {
                        this.$message.error("获取设备信息失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            getList(){
                this.apiUtils.cameraImageListGet({sn:this.id, pageSize:this.pageSize, pageNo:this.pageNo }).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.cameraList = res.data.list
                        this.count = res.data.count

                        let list = [];
                        this.idList=[];
                        for (let item of this.cameraList) {
                            list.push(item.type == 'IOT-CAMERA' ? 'https://api.iezfarmer.com/iotapi'+item.imageID : 'https://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID)
                            this.idList.push(item.id);
                        }
                        this.viewerList = list;
                     //   this.pageSize = res.pageSize;
                      //  this.pageNo = res.pageNo
                    } else {
                        this.$message.error("获取抓图列表失败! " + res.message);
                        console.log(res);
                    }

                });
            },
            swith(index){
                console.error(index, this.idList)
                if (index == 0){
                    this.getPre(this.idList[index])
                } else if (index == (this.viewerList.length-1)){
                    this.getNext(this.idList[index])
                }
            },
            getPre(imgID){
                console.error(imgID)
                this.apiUtils.cameraImagePre({sn:this.id, id: imgID, size:10}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        let data = res.data
                       if (data){
                           let list = [];
                           let idS = [];
                           for (let item of data) {
                               list.push(item.type == 'IOT-CAMERA' ? 'https://api.iezfarmer.com/iotapi'+item.imageID : 'https://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID)
                               idS.push(item.id)
                           }
                           for (let item of this.viewerList) {
                               list.push(item)
                           }
                           for (let item of this.idList) {
                               idS.push(item)
                           }
                           this.idList = idS;
                           this.viewerList = list;
                           this.imageIndex = data.length;
                       }

                    } else {

                    }

                });
            },
            getNext(imgID){
                console.error(imgID)
                this.apiUtils.cameraImageNext({sn:this.id, id: imgID, size:10}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        let data = res.data
                        if (data){
                            let list = [];
                            let idS = [];
                            for (let item of this.viewerList) {
                                list.push(item)
                            }
                            for (let item of this.idList) {
                                idS.push(item)
                            }
                            for (let item of data) {
                                list.push(item.type == 'IOT-CAMERA' ? 'https://api.iezfarmer.com/iotapi'+item.imageID : 'https://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID)
                                idS.push(item.id)
                            }
                            this.idList = idS;
                            this.viewerList = list;
                        }
                    } else {

                    }

                });
            },
            share(v){
                console.error(">>>>>>>>>>>>>>>>>>>>>>>>> " + v)
                this.info.isLoading = true;
                this.apiUtils.cameraImageShare({sn:this.id, share:this.info.share, sharePassword:this.info.sharePassword}).then(res => {
                    if (res.code == 200){
                        console.log(res.data);
                        this.info = res.data
                    } else {
                        this.$message.error("设置分享失败! " + res.message);
                        console.log(res);
                    }

                }).finally(()=>{ this.info.isLoading = false});
            },
            // 初始页currentPage、初始每页数据数pagesize和数据data
            handleSizeChange: function (size) {
                this.pageSize = size;
                this.getList();
            },
            // 分页导航
            handlePageChange(val) {
                this.pageNo = val
                this.getList();
            },
            onPreview(item) {
                this.currentSrc = item.type == 'IOT-CAMERA' ? 'https://api.iezfarmer.com/iotapi'+item.imageID : 'https://36.137.213.11:37202/api/image/'+item.sn+'/'+item.year+'/'+item.month+'/'+item.imageID;
                this.imageIndex = this.viewerList.indexOf(this.currentSrc)
                this.showViewer = true
            },
            closeViewer() {
                console.error("..............................")
                this.showViewer = false

            },
        }
    }
</script>

<style scoped>

    /deep/ .is-disabled{
        visibility: hidden;
    }
     /deep/ .el-image__error, /deep/ .el-image__inner, /deep/ .el-image__placeholder{
        width: auto;
    }
     /deep/ .el-input__inner{
         height: 30px;
         line-height: 30px;
         padding: 0 8px;
     }

    .time {
        font-size: 13px;
        color: #999;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .button {
        padding: 0;
        min-height: auto;
    }
</style>