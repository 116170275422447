<template>
  <el-container style="height: 100%; ">

    <el-container>
      <el-header style="text-align: right; font-size: 12px; height: auto; padding: 0px 10px 10px 20px">
<!--          <div class="v-box" style="padding: 8px; background-color: #fff; border-radius: 5px;">
              <img :src="coverSrc" class="cover-img" style="width: 100px"/>
          </div>-->
          <div class="v-box" style="margin: 8px 0 0 0">
<!--              <el-input v-model="searchForm.name" placeholder="请输入计划名称" style="width: 200px; margin-right: 16px"></el-input>
              <el-input v-model="searchForm.name" placeholder="请输入批次号" style="width: 200px; margin-right: 16px"></el-input>
              <el-input v-model="searchForm.name" placeholder="请输入负责人" style="width: 200px; margin-right: 16px"></el-input>
              <el-select v-model="searchForm.state" placeholder="请选择状态" style="width: 200px; margin-right: 16px">
                  <el-option key="-1" label="全部" value="-1"/>
                  <el-option key="0" label="实施中" value="0"/>
                  <el-option key="1" label="已结束" value="1"/>
              </el-select>
              <el-button size="medium" style="margin: 0 8px 0 0">重置</el-button>
              <el-button size="medium" style="margin: 0 8px 0 0">搜索</el-button>-->
              <div style="flex: 1">
                  <el-button size="medium" type="primary" style="margin: 0 0 0 0; float: right" @click="addWorker">添加员工</el-button>
              </div>
          </div>
      </el-header>

      <el-main style="padding: 0px 10px 10px 20px">
        <div class="container" >
          <el-table
              :data="workerList"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              :show-header="true"
          >
            <el-table-column label="序号" type="index" width="50">
            </el-table-column>
            <el-table-column label="员工姓名" prop="name">
              <template #default="scope">
                <p class="nameHint">{{ scope.row.name }}</p>
              </template>
            </el-table-column>
            <el-table-column label="性别" prop="sex">
              <template #default="scope">
                <p class="nameHint">{{ scope.row.sex }}</p>
              </template>
            </el-table-column>
            <el-table-column label="工号" prop="workID" >
              <template #default="scope">
                <p >{{ scope.row.workID }}</p>
              </template>
            </el-table-column>
            <el-table-column label="手机号码" prop="phone" >
              <template #default="scope">
                <p >{{ scope.row.phone }}</p>
              </template>
            </el-table-column>
            <el-table-column label="身份证号码" prop="idCard" >
              <template #default="scope">
                <p >{{ scope.row.idCard }}</p>
              </template>
            </el-table-column>
            <el-table-column label="银行卡号码" prop="bankCard" >
              <template #default="scope">
                <p >{{ scope.row.bankCard }}</p>
              </template>
            </el-table-column>

            <el-table-column label="创建时间" prop="createTime" width="120">
              <template #default="scope">
                <p style="white-space: pre-line; text-align: right; width: auto; float: left"  v-html="myFormatDate(scope.row.createTime)"></p>
              </template>
            </el-table-column>

            <el-table-column label="状态" prop="state" >
              <template #default="scope">
                <p >{{ '正常'}}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template #default="scope">
                <div>
<!--                  <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">查看</el-button>-->
                  <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px"  @click="editWorker(scope.row)">修改</el-button>
                  <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px"  @click="refreshWorker(scope.row)">刷新</el-button>
<!--                  <el-button type="text"  style="padding-top: 5px; padding-bottom: 5px; min-height: 26px; color: #F56C6C"  @click="removeWorker(scope.row)">移除</el-button>-->
                </div>
              </template>
            </el-table-column>


          </el-table>
          <div class="pagination" style="width: 100%">
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[8, 12, 20, 40, 100]"
                :current-page="pageNo"
                :page-size="pageSize"
                :total="count"
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>

      </el-main>


    </el-container>
    <WorkerEditDialog :visible="workerDialogVis" @cancel="workerDialogVis = false" :form="workerForm" @submit="workerSubmit"></WorkerEditDialog>

  </el-container>
</template>

<script>
import WorkerEditDialog from "./WorkerEditDialog";
import {formatDate} from '../js/formatDate.js';
export default {
  name: "WorkerManage",
  components: {WorkerEditDialog},
  data () {
    return {
      coverSrc:require("../../assets/img/zan_wu.png"),
      workerList:[],
      landList:[],
      searchForm:{
        id:null,
        name:null,
        director:null,
        state:'-1'
      },
      workerDialogVis:false,
      workerForm:{
        formType: 0,   //0新增 1编辑
        name:'',
        workID:0,
        sex:'男',
        phone:'',
        idCard:'',
        bankCard:'',
        createTime:'',
        username: '',
        deptID: '',
        deptName:'',
        roleID:'',
        roleName:'',
        permission:'',
      },
      pageNo: 1,
      pageSize: 8,
      count:0,
    }
  },
  mounted(){
    this.getList();

  },
  methods:{
    myFormatDate(time) {
      let date = new Date(time);
      let str = formatDate(date, 'hh:mm:ss\n[yyyy-MM-dd]');
      return str.replace('[', '<p style="font-size: 10px; color: #b5b5b5">').replace(']', '</p>');
    },
    getList(){
      this.apiUtils.syWorkerListGet({pageSize:this.pageSize, pageNo:this.pageNo}).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.count = res.data.count
          this.workerList = res.data.list
        } else {
          this.$message.error("获取人员列表失败! " + res.message);
          console.log(res);
        }

      });
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pageSize = size;
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.pageNo = val
      this.getList();
    },
    editWorker(row){
      this.workerForm = {
        formType: 1,   //0新增 1编辑

        username: row.username,
        deptID: row.deptID,
        deptName:row.deptName,
        roleID:row.roleID,
        roleName:row.roleName,
        permission:row.permission,

        name:row.name,
        workID:row.workID,
        sex:row.sex,
        phone:row.phone,
        idCard:row.idCard,
        bankCard:row.bankCard,
        createTime:row.createTime
      }
      this.workerDialogVis = true;
    },
    addWorker(){
      this.workerForm = {
        formType: 0,   //0新增 1编辑
        name:'',
        workID:0,
        sex:'男',
        phone:'',
        idCard:'',
        bankCard:'',
        createTime:'',
        username: '',
        deptID: '',
        deptName:'',
        roleID:'',
        roleName:'',
        permission:'',
      }
      this.workerDialogVis = true;
    },
    removeWorker(row){
      this.$confirm('此操作将移除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {

            this.$message.info("该功能尚在建设中! ");

          })
          .catch(() => {

          })
    },
    refreshWorker(row){
      this.apiUtils.syWorkerRefresh({
        workID:row.workID
      }).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.$message.success("刷新成功");
          this.getList();
        } else {
          this.$message.error("刷新失败! " + res.message);
          console.log(res);
        }

      });
    },
    workerSubmit(form){
      console.error(form)
      if (form.formType == 0){
        this.apiUtils.syWorkerAdd({
          password: form.phone.substring(form.phone.length - 4),
          username: form.phone,
          deptID: 1001,
          deptName:'莲花基地',
          roleID:1001,
          roleName:'员工',
          permission:'1',

          name: form.name,
          sex:form.sex,
          phone:form.phone,
          idCard:form.idCard,
          bankCard:form.bankCard,
          createTime:form.createTime
        }).then(res => {
          if (res.code == 200){
            console.log(res.data);
            this.workerDialogVis = false;
            this.getList();
          } else {
            this.$message.error("增加员工失败! " + res.message);
            console.log(res);
          }

        });
      }else{
        this.apiUtils.syWorkerEdit({

          username: form.username,
          deptID: form.deptID,
          deptName:form.deptName,
          roleID:form.roleID,
          roleName:form.roleName,
          permission:form.permission,

          name: form.name,
          sex:form.sex,
          phone:form.phone,
          idCard:form.idCard,
          bankCard:form.bankCard,
          createTime:form.createTime
        }).then(res => {
          if (res.code == 200){
            console.log(res.data);
            this.workerDialogVis = false;
            this.getList();
          } else {
            this.$message.error("编辑员工信息失败! " + res.message);
            console.log(res);
          }

        });
      }

    },
  }
}
</script>

<style scoped>
/deep/ .el-table td, /deep/ .el-table th{
  padding: 6px 0;
  font-size: 13px;
}

/deep/ .el-table .cell{
  padding-left: 5px;
  padding-right: 5px;
}
</style>