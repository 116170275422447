<template>
  <div>
    <el-dialog title="定时任务"  v-model="dialogFormVisible" @close="$emit('cancel')">
      <el-form :model="myform">
        <div class="h-box">
          <div class="v-box" style="margin-bottom: 8px">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>任务名称:</div>
            <el-input v-model="myform.name"  style="width: 150px; margin-left: 16px"></el-input>
          </div>

          <div class="v-box" style="margin-bottom: 8px" v-show="false">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>模式:</div>
            <el-radio-group v-model="myform.type" style="margin-left: 16px">
              <el-radio-button :label="0">常用</el-radio-button>
              <el-radio-button :label="1">自定义</el-radio-button>
            </el-radio-group>
          </div>

          <div class="v-box" style="margin-bottom: 8px" v-show="myform.type == 1">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>表达式:</div>
            <el-autocomplete v-model="myform.cron" style="width: 150px; margin-right: 10px; margin-left: 16px"   :fetch-suggestions="querySearch">
              <template #default="{ item }">
                <div style="height: 20px; font-size: 14px; color: #324157">{{ item.value }}</div>
                <span  style="width:400px; height: 20px; font-size: 10px; color: #97a8be">{{ item.link }}</span>
              </template>
            </el-autocomplete>
            <el-popover placement="bottom" :width="800" trigger="manual" v-model:visible="cronDIY">
              <VueCronLinux :data="myform.cron" @change="cronChange"></VueCronLinux>
              <div style="width: 100%; margin-top: 10px">
                <el-button size="small" type="primary" style="float: right" @click="cronSure">确定</el-button>
                <el-button size="small" style="float: right; margin-right: 10px" @click="cronDIY = !cronDIY">取消</el-button>
              </div>
              <template #reference>
                <el-button type="primary" size="small" icon="el-icon-edit" circle @click="cronDIY = !cronDIY"></el-button>
              </template>
            </el-popover>
          </div>

          <!-- <el-form-item label="任务名称" :label-width="formLabelWidth" v-show="myform.type == 1">
               <el-input v-model="myform.cron" style="width: 200px; margin-right: 10px"></el-input>
               <el-popover placement="bottom" :width="400" trigger="click">
                   <VueCronLinux :data="myform.cron" ></VueCronLinux>
                   <template #reference>
                       <el-button type="primary" icon="el-icon-edit" circle ></el-button>
                   </template>
               </el-popover>
           </el-form-item>-->

          <div class="v-box" style="margin-bottom: 8px" v-show="myform.type != 1">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>重复:</div>
            <el-radio-group v-model="myform.runType" style="margin-left: 16px">
              <el-radio label="0">一次</el-radio>
              <el-radio label="1">每天</el-radio>
              <el-radio label="2">自定义</el-radio>
            </el-radio-group>
          </div>
          <div class="v-box" style="margin-bottom: 4px">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px">执行任务:</div>
          </div>
          <div class="v-box" style="margin-bottom: 8px">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>开始:</div>
            <el-select v-model="myform.startAction" placeholder="请选择" @change="startActionChange" style="width: 100px; margin-left: 16px">
              <el-option label="打开" value="ON"></el-option>
              <el-option label="关闭" value="OFF"></el-option>
            </el-select>
            <div style="height: 40px;  line-height: 40px; margin-left: 16px">延迟:</div>
            <el-input v-model="myform.delayTime" type="number" min="5" max="36000" style="width: 100px; margin-left: 16px"></el-input>
            <span style="height: 40px; line-height: 40px; margin-left: 8px">秒</span>

            <div style="height: 40px;  line-height: 40px; margin-left: 16px">结束时:</div>
            <el-select v-model="myform.endAction" placeholder="请选择" @change="endActionChange" style="width: 100px; margin-left: 16px">
              <el-option label="打开" value="ON"></el-option>
              <el-option label="关闭" value="OFF"></el-option>
            </el-select>

          </div>

          <div class="v-box" style="margin-bottom: 8px">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>任务循环执行:</div>
            <el-input v-model="myform.repeatTimes" type="number" min="1" max="100"  style="width: 100px; margin-left: 16px"></el-input>
            <span style="height: 40px; line-height: 40px; margin-left: 8px">次</span>

            <div style="height: 40px;  line-height: 40px; margin-left: 16px">间隔:</div>

            <el-input v-model="myform.intervalTime" type="number" min="5" max="36000" style="width: 100px; margin-left: 16px"></el-input>
            <span style="height: 40px; line-height: 40px; margin-left: 8px">秒</span>
          </div>
          <div class="v-box" style="margin-bottom: 8px">
            <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>策略优先级:</div>
            <el-input v-model="myform.priority" type="number" min="1" max="100"  style="width: 100px; margin-left: 16px"></el-input>
            <span style="height: 40px; line-height: 40px; margin-left: 8px">次</span>
          </div>
        </div>


      </el-form>
      <template #footer>
            <span class="dialog-footer">
                <el-button @click="$emit('cancel')">取 消</el-button>
                <el-button type="primary" @click="submit(myform)">确 定</el-button>
             </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
    import {computed} from 'vue'
    import VueCronLinux from "./VueCronLinux";
    export default {
        name: "TimingTaskDialog",
        components: {VueCronLinux},
        props:{
            visible:{type:Boolean, default:false},
            form: {
                type:Object,
                default(){
                    return{
                        type: 1,
                        name: '',
                        cron: '0 0 * * * *',
                        startAction: 'ON',
                        delayTime: 300,
                        endAction: 'OFF',
                        repeatTimes: 1,
                        intervalTime: 300,
                        priority:1
                    }
                }
            },
        },
        setup(props){//提供的函数props直接用就完事了嗷~~~

        },
        data() {
            return {
                cronDIY: false,
                cronStr:'',
                dialogFormVisible: this.visible,
                myform: this.form,
                formLabelWidth: '120px'
            };
        },
        watch: {
            form(newV, oldV) {
                this.myform = newV
            },
            visible(newV, oldV) {
                this.dialogFormVisible = newV
            }
        },
        methods:{
            submit(myf){
                if (myf.name == null || myf.name.length<1){
                    this.$message.error("请填写任务名称!" );
                    return
                }

                this.$emit('submit',myf)
            },
            startActionChange(){
                if (this.myform.startAction == 'ON' && this.myform.endAction != 'OFF'){
                    this.myform.endAction = 'OFF'
                } else if (this.myform.startAction == 'OFF' && this.myform.endAction != 'ON'){
                    this.myform.endAction = 'ON'
                }
            },
            endActionChange(){
                if (this.myform.endAction == 'ON' && this.myform.startAction != 'OFF'){
                    this.myform.startAction = 'OFF'
                } else if (this.myform.endAction == 'OFF' && this.myform.startAction != 'ON'){
                    this.myform.startAction = 'ON'
                }
            },
            querySearch(queryString, cb) {
                cb([
                    {value:'0 0 8 * * 2,4,6',link:'每周二、周四、周六的8点整开启任务'},
                    {value:'0 0 8 * * *',link:'每天8点整开启任务'},
                    {value:'0 0 8 * * *',link:'每天8点整开启任务'},
                    {value:'0 0 8-20 * * *',link:'每天8点-20点之间的整点开启任务'}
                    ])
            },
            cronChange(cron){
                this.cronStr = cron;
                //console.error(cron);
            },
            cronSure(){
                this.cronDIY = false;
                this.myform.cron = this.cronStr;
            }
        },
    }
</script>

<style>
    .el-dialog{
        min-width: 800px !important;
    }
    .el-autocomplete-suggestion{
        width: auto !important;
    }
</style>
<style scoped>
    * {
        margin: auto 0;
    }
    /deep/ .el-date-editor--daterange {
        margin: auto 0;
    }
    /deep/ .select-trigger {
        margin: auto 0;
    }
    /deep/ .el-range-editor.el-input__inner{
        padding-top: 0;
        padding-bottom: 0;
    }
    /deep/ .el-input__suffix {
        line-height: 30px;
    }
    /deep/ .el-input__inner{
        height: 30px !important;
        line-height: 30px !important;
    }
    /deep/ .el-input__icon{
        line-height: 30px;
    }
</style>

