<template>
  <div class="v-box" style="flex-wrap: wrap">
    <div>
      <canvas id="myCanvas" width="900" height="900" style=" background: white; border: 0px solid black"></canvas>
    </div>


    <div class="item" v-for="(item, index) in list" :key="index" @click="itemClick(item)">
<!--      <img :src="item.imgSrc" class="cover-img" />-->
      <span class="item-title">{{item.title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Other",
  components: {},
  data () {
    return {
      shipsSrc:require("../../assets/img/ships.png"),
      list:[],
      radarInfo:{},
      objectList: []
    }
  },
  mounted(){
    let account = localStorage.getItem("userInfo") == null ? null
        : JSON.parse(localStorage.getItem("userInfo")).company.account;
    if (account == "syzx"){
      let syList = [{title: '刷新', imgSrc:require("../../assets/img/ships.png")}];
      this.list = syList;
    }
    this.drawRadar();

    setInterval(() => {
      this.getLast()
    }, 300);  // 滚动速度

  },
  methods:{
    itemClick(item){
      if (item.title == '刷新'){
        let canvas = document.getElementById("myCanvas");

        console.log(canvas.width + " _ " + canvas.height)

        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, 900, 900);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
    },
    drawRadar(){

      let canvas = document.getElementById("myCanvas");

      console.log(canvas.width + " _ " + canvas.height)

      let padding = 50

      let realyW = canvas.width - 2*padding;
      let realyH = canvas.height - 2*padding;

      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);


      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      let xStart = -100;
      let xEnd = 100;
      let yStart = 0;
      let yEnd = 100;

      let xScale = realyW/(xEnd - xStart);
      let yScale = realyH/(yEnd - yStart)

      ctx.lineWidth = 1;

      let hCount = 20;
      let rectH = realyH/hCount;

      ctx.strokeStyle = '#c9c9c9'

      //横线
      for (let i = 0; i < hCount+1; i++) {
        ctx.moveTo(0+padding, rectH * i-0.5+padding);
        ctx.lineTo(realyW+padding, rectH * i-0.5+padding);

      }

      let wCount = 20;
      let rectW = realyW/wCount;


      //竖线
      for(let i=0;i<wCount+1;i++){
        ctx.moveTo(rectW * i-0.5+padding, 0+padding);
        ctx.lineTo(rectW * i-0.5+padding, realyH+padding);

      }
      ctx.stroke();

      ctx.fillStyle = '#575757'
      ctx.font = "15pt Calibri";
      ctx.textBaseline = "middle"
      ctx.textAlign = 'center';

      for(let i=0;i<wCount+1;i++){
        ctx.fillText(yStart+(hCount-i)*rectH/yScale, padding/2, rectH * i-0.5+padding);
        ctx.fillText(xStart+i*rectW/xScale, rectW * i-0.5+padding,canvas.height-padding/2);
      }

      let oW = 20;

      ctx.fillStyle = '#000000'

      ctx.font = "15pt Calibri";
      ctx.textAlign = 'center';
      ctx.textBaseline = "bottom"

      if (this.objectList){
        console.log(this.objectList.length)
        for (let i = 0; i < this.objectList.length; i++) {
          let item = this.objectList[i];
          let x = (item.x - xStart) * xScale + padding;
          let y = realyH+padding - ((item.y - yStart) * yScale + oW/1.73);

          let text = "ID:"+item.id+",A:"+item.a+"°,R:"+item.r+"m";
          ctx.fillText(text,x,y-5);
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(x-oW/2, y+0.866*oW);
          ctx.lineTo(x+oW/2, y+0.866*oW);
          ctx.lineTo(x, y);
          ctx.fill();     // 有填充色
          ctx.closePath();
        }
      }

      ctx.stroke();

    },
    getLast(){
      this.apiUtils.radarLast({sn:"0D2208260001"}).then(res => {
        if (res.code == 200){
          this.radarInfo = res.data
          if (!res.data || !res.data.objectList) return;
          let his = res.data.objectList;
          let arr = []
          for (let i = 0; i < his.length; i++) {
            let ob = his[i]
            arr.push({
              x: ob.obDistLat,
              y: ob.obDistLong,
              id: ob.id,
              a: ob.obAngle,
              r: ob.obRadian
            })
          }
          this.objectList = arr;
          this.drawRadar()
        } else {
          this.$message.error("获取轨迹失败! " + res.errInfo);
          console.log(res);
        }

      });
    },
  }
}
</script>

<style scoped>

.item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 300px;
  background: white;
  border-radius: 5px;
  text-align: center;
  margin: 8px;
  box-shadow: 0px 0px 1px 1px rgba(148, 162, 162, 0.2);
}
.item:active{
  background: #f3f3f3;
}
.item-title{
  vertical-align: middle;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-top: 16px;
}
.cover-img{
  width: 150px;
  height: 150px;
}


/deep/ .el-table td, /deep/ .el-table th{
  padding: 6px 0;
  font-size: 13px;
}

/deep/ .el-table .cell{
  padding-left: 5px;
  padding-right: 5px;
}
</style>