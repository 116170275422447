<template>
  <el-container style="height: 100%; ">

    <el-container>
      <el-header style="text-align: right; font-size: 12px; height: auto; padding: 0px 10px 10px 20px">
        <!--          <div class="v-box" style="padding: 8px; background-color: #fff; border-radius: 5px;">
                      <img :src="coverSrc" class="cover-img" style="width: 100px"/>
                  </div>-->
        <div class="v-box" style="margin: 8px 0 0 0">
<!--          <el-date-picker
              v-model="searchForm.startTime"
              type="date"
              placeholder="开始日期"
              style="width: 200px; margin-right: 16px"
          />
          <el-date-picker
              v-model="searchForm.endTime"
              type="date"
              placeholder="结束日期"
              style="width: 200px; margin-right: 16px"
          />
          <el-input v-model="searchForm.workName" placeholder="请输入员工真实姓名" style="width: 200px; margin-right: 16px"></el-input>

          <el-button size="medium" style="margin: 0 8px 0 0" @click="searchFormChange">查看</el-button>
          <div style="flex: 1">
            &lt;!&ndash;            <el-button size="medium" type="primary" style="margin: 0 0 0 0; float: right" @click="addWorker">添加员工</el-button>&ndash;&gt;
          </div>-->
        </div>
      </el-header>

      <el-main style="padding: 0px 10px 10px 20px">
        <div class="container" >
          <el-table
              :data="classplanList"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              :show-header="true"
          >



            <el-table-column label="序号" type="index" width="50">
            </el-table-column>

            <el-table-column label="计划名称" prop="planName">
              <template #default="scope">
                <p class="nameHint">{{ scope.row.planName }}</p>
              </template>
            </el-table-column>
            <el-table-column label="开始日期" prop="startTime">
              <template #default="scope">
                <p class="nameHint">{{ myFormatDate2(scope.row.startTime) }}</p>
              </template>
            </el-table-column>
            <el-table-column label="结束日期" prop="endTime">
              <template #default="scope">
                <p class="nameHint">{{ myFormatDate2(scope.row.endTime) }}</p>
              </template>
            </el-table-column>


            <!--            <el-table-column label="状态" prop="state" >
                          <template #default="scope">
                            <p >{{ 0==state ? '待确认' : '已确认'}}</p>
                          </template>
                        </el-table-column>-->

            <el-table-column label="操作" width="120">
              <template #default="scope">
                <div>
                  <!--                  <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px">待确认</el-button>-->
                  <el-button type="text" style="padding-top: 5px; padding-bottom: 5px; min-height: 26px"  @click="toInfo(scope.row)">详情</el-button>
                  <!--                  <el-button type="text"  style="padding-top: 5px; padding-bottom: 5px; min-height: 26px; color: #F56C6C"  @click="removeWorker(scope.row)">移除</el-button>-->
                </div>
              </template>
            </el-table-column>


          </el-table>
          <div class="pagination" style="width: 100%">
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[8, 12, 20, 40, 100]"
                :current-page="pageNo"
                :page-size="pageSize"
                :total="count"
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>

      </el-main>


    </el-container>
    <WorkstatEditDialog :visible="classplanDialogVis" @cancel="classplanDialogVis = false" :form="classplanForm" @submit="classplanSubmit"></WorkstatEditDialog>

  </el-container>
</template>

<script>
import WorkstatEditDialog from "./WorkstatEditDialog";
import {formatDate} from '../js/formatDate.js';
export default {
  name: "ClassPlanManage",
  components: {WorkstatEditDialog},
  data () {
    return {
      coverSrc:require("../../assets/img/zan_wu.png"),
      classplanList:[],
      landList:[],
      searchForm:{
        startTime:null,
        endTime:null,
        workName:null,
      },
      classplanDialogVis:false,
      classplanForm:{
        formType: 0,   //0新增 1编辑
        workName:'',

        belongWorkID: 0,
        dateTime: 0,

        workType: 'NULL',
        overDuration:0,
        workDuration:0,
      },
      pageNo: 1,
      pageSize: 8,
      count:0,
    }
  },
  mounted(){
    this.getList();

  },
  methods:{

    myFormatDate(time) {
      if (!time) return "-";
      let date = new Date(time);
      let str = formatDate(date, 'hh:mm:ss');
      return str;
    },
    myFormatDate2(time) {
      if (!time) return "-";
      let date = new Date(time);
      let str = formatDate(date, 'yyyy-MM-dd hh:mm:ss');
      return str;
    },
    getHourStr(m) {
      if (!m) return "-";
      let hour = (m/60).toFixed(1);
      return hour+"小时";
    },
    searchFormChange(){
      this.pageNo = 1;
      this.getList();
    },
    toInfo(row){
      let path = '/classplaninfo/'+row.id;
      let list = this.$store.state.tagsList
      let isExist = list.some(item => {
        return item.path === path;
      });
      let name = row.planName == null ? row.id : row.planName
      if (!isExist) {
        if (list.length >= 8) {
          this.$store.commit("delTagsItem", { index: 0 });
        }
        this.$store.commit("setTagsItem", {
          name: name,
          title: name,
          path: path
        });
      }

      this.$router.push(path)
    },
    getList(){
      this.apiUtils.syClassPlanListGet({pageSize:this.pageSize, pageNo:this.pageNo,
        startTime: this.searchForm.startTime ? this.searchForm.startTime.getTime() : null,
        endTime: this.searchForm.endTime ? this.searchForm.endTime.getTime() : null,
        name: this.searchForm.workName ? this.searchForm.workName : null}).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.count = res.data.count
          this.classplanList = res.data.list
        } else {
          this.$message.error("获取考勤列表失败! " + res.message);
          console.log(res);
        }

      });
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (size) {
      this.pageSize = size;
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.pageNo = val
      this.getList();
    },
    editClassplan(row){
      this.classplanForm = {
        formType: 1,   //0新增 1编辑

        workName:row.workName,

        belongWorkID: row.belongWorkID,
        dateTime: row.dateTime,

        workType: row.workType,
        overDuration: row.overDuration,
        workDuration: row.workDuration
      }
      this.classplanDialogVis = true;
    },
    removeClassplan(row){
      this.$confirm('此操作将移除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {

            this.$message.info("该功能尚在建设中! ");

          })
          .catch(() => {

          })
    },
    classplanSubmit(form){
      console.error(form)
      this.apiUtils.syClassplanEdit({

        belongWorkID: form.belongWorkID,
        dateTime: form.dateTime,

        workType: form.workType,
        overDuration: form.overDuration,
        workDuration: form.workDuration,
      }).then(res => {
        if (res.code == 200){
          console.log(res.data);
          this.classplanDialogVis = false;
          this.getList();
        } else {
          this.$message.error("编辑考勤信息失败! " + res.message);
          console.log(res);
        }

      });

    },
  }
}
</script>

<style scoped>
/deep/ .el-table td, /deep/ .el-table th{
  padding: 6px 0;
  font-size: 13px;
}

/deep/ .el-table .cell{
  padding-left: 5px;
  padding-right: 5px;
}
</style>