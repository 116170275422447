<template>
  <el-dialog title="编辑人员信息" v-model="dialogFormVisible" @close="$emit('cancel')">
    <el-form :model="myform">
      <div class="h-box">


        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>员工:</div>

          <el-select v-model="myform.workID"  placeholder="选择员工" style="width: 150px; margin-left: 16px">
            <el-option
                v-for="item in userList"
                :key="item.workID"
                :label="item.name"
                :value="item.workID"
            />
          </el-select>
        </div>

        <div class="v-box" style="margin-bottom: 8px">
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px"><span style="color: red">*</span>班次:</div>

          <el-select v-model="myform.classID"  placeholder="选择班次" style="width: 150px; margin-left: 16px"
                     @change="classChange">
            <el-option
                v-for="item in classList"
                :key="item.id"
                :label="item.name"
                :value="item.id"

            />
          </el-select>
        </div>

        <div class="v-box" style="margin-bottom: 8px" >
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px">上班时间:</div>
          <span style="width: 150px; margin-left: 16px">{{getDayTimeStr(startTime)}}</span>
        </div>

        <div class="v-box" style="margin-bottom: 8px" >
          <div style="width: 100px; height: 40px; text-align: right; line-height: 40px">下班时间:</div>
          <span style="width: 150px; margin-left: 16px">{{getDayTimeStr(endTime)}}</span>
        </div>

      </div>
    </el-form>
    <template #footer>
            <span class="dialog-footer">
                <el-button @click="$emit('cancel')">取 消</el-button>
                <el-button type="primary" @click="submit(myform)">确 定</el-button>
             </span>
    </template>
  </el-dialog>
</template>

<script>
import {computed} from 'vue'
import VueCronLinux from "./VueCronLinux";
import {formatDate} from '../js/formatDate.js';
export default {
  name: "UserClassEditDialog",
  components: {VueCronLinux},
  props:{
    visible:{type:Boolean, default:false},
    userList:{type:Array, default:[]},
    classList:{type:Array, default:[]},
    form: {
      type:Object,
      default(){
        return{
          formType: 0,   //0新增 1编辑

          planID:0,
          workID:0,
          classID:0

        }
      }
    },
  },
  setup(props){//提供的函数props直接用就完事了嗷~~~

  },
  data() {
    return {
      dialogFormVisible: this.visible,
      myform: this.form,
      directorList:[],
      formLabelWidth: '120px',
      startTime:null,
      endTime:null,
    };
  },
  watch: {
    form(newV, oldV) {
      this.myform = newV
    },
    visible(newV, oldV) {
      this.dialogFormVisible = newV
    },
  },
  methods:{
    myFormatDate2(time) {
      if (!time) return "-";
      let date = new Date(time);
      let str = formatDate(date, 'yyyy-MM-dd');
      return str;
    },
    getDayTimeStr(m) {
      if (!m) return "-";
      let min = m%60;
      let hour = ((m-min)/60).toFixed(0);
      return (hour>9 ? hour : '0'+hour)+":"+(min>9 ? min : '0'+min);
    },
    submit(myf){
      console.error("myf:", myf)
      if (myf.workID == null || myf.workID<1){
        this.$message.error("请选择员工!" );
        return
      }
      if (myf.classID == null || myf.classID<1){
        this.$message.error("请选择班次!" );
        return
      }
      this.$emit('submit',myf)
    },
    classChange(id){
      console.error("change......", id)
      for (let i = 0; i < this.classList.length; i++) {
        if (id == this.classList[i].id){
          this.startTime = this.classList[i].startTime
          this.endTime = this.classList[i].endTime
        }
      }

    },
  }
}
</script>

<style scoped>
* {
  margin: auto 0;
}
/deep/ .el-date-editor--daterange {
  margin: auto 0;
}
/deep/ .select-trigger {
  margin: auto 0;
}
/deep/ .el-range-editor.el-input__inner{
  padding-top: 0;
  padding-bottom: 0;
}
/deep/ .el-input__suffix {
  line-height: 30px;
}
/deep/ .el-input__inner{
  height: 30px;
  line-height: 30px;
}
/deep/ .el-input__icon{
  line-height: 30px;
}
</style>