<template>
  <div style="height: 100%">
    <!--src 替换成MapIframe相应的绝对地址-->
    <iframe id="map_iframe" ref="iframe" :src="mapSrc" frameborder="0" width="100%" height="100%"></iframe>
    <div style="width: auto; height: auto; right: 20px; top: 50px; position: absolute; cursor: pointer; z-index: 1000;" >
      <el-button type="primary" @click="timeSelect">
        选择时间
      </el-button>

      <el-button type="primary" @click="autoSelect">
        {{isAuto ? '暂停播放' : '自动播放'}}
      </el-button>
    </div>


    <el-drawer
        v-model="drawerOpen"
        title="历史轨迹"
        direction="rtl"
        @opened="drawerOpened"
        size="40%">
      <div style="display: flex; flex-direction: column; height: 100%">
        <div style="display: flex; justify-content: flex-start; align-items: center; height: 50px">
          <el-date-picker
              v-model="selectDate"
              @change="dateChange"
              type="date"
              placeholder="选择日期"
          />

          <el-time-picker
              style="margin-left: 20px"
              v-model="selectTime"
              is-range
              range-separator="-"
              @change="timeChange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </div>
        <div style="flex-grow: 1; margin-top: 20px" class="table-box" ref="tableBox">
          <el-table :data="tableData" style="width: 100%;"  :max-height="tableHeight+'px'">
            <el-table-column prop="date" label="时间" width="180" />
            <el-table-column prop="lng" label="经度" width="100" />
            <el-table-column prop="lat" label="纬度" width="100" />
            <el-table-column prop="speed" label="速度" width="50" />
            <el-table-column fixed="right" label="操作">
              <template #default="scope">
                <el-button
                    link
                    type="primary"
                    @click="gpsPointClick(scope.row)"
                    size="small">
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>

    </el-drawer>

    <div v-show="false">
      <!--图钉上的弹出框-->
      <div id="balloon-popup-video" class="mymappopper">
        <div style="width: 200px;">
<!--          <img src="img/close.png" width="20" height="20"/>-->
          <span class="mymaptitle">[name]</span>
          <!--        <span class="mymaptag">[state]</span>-->
        </div>
        <div class="mymapcontent">经纬度：[lnglat]</div>
        <div class="mymapcontent">速度：[speed]</div>
        <!--      <div class="mymapcontent">更新时间：[time]</div>
              <div class="mymapbutton" onclick="mapButtonClick([eventName], [eventParam])">
                查看监控
              </div>-->
      </div>
    </div>

  </div>

</template>
<script>
import {gps_to_bd} from '../js/lntlat.js';
/* eslint-disable */
let that;
export default {
  name: "MapTest3",
  data () {
    return {
      sn:this.$route.params.sn,
      startTime: new Date(new Date().toLocaleDateString()).getTime(),
      endTime: new Date().getTime(),
      onCtrl: false,
      mapSrc:'https://api.iezfarmer.com/map/#/gpsbdmapiframe?type=XZ&lng=118.852618&lat=28.689569&level=17&bdstyle=1',
     // mapSrc:'http://192.168.1.215:8081/map//#/gpsbdmapiframe?type=XZ&lng=118.852618&lat=28.689569&level=17&bdstyle=1',
       //修改设别信息对话框
      color:'#FFF55A',
      selectPointVis:false,
      loading: false,
      cameraForm:{sn:'', name:'', src:'', lng:0, lat:0, asl:0},
      selectGroup:'全部',
      groupList:["全部","路东","路西"],
      deviceVis: true,
      traceList: [],

      //设备列表
      deviceList:[],

      iframeIndex:0,  //位于最上层的实时预览弹出框 编号
      iframeContent:null,


      selectDate:  new Date(new Date().toLocaleDateString()),
      selectTime: [new Date(new Date().toLocaleDateString()), new Date(new Date(new Date().toLocaleDateString()).getTime() + 24*1000*3600 - 1)],
      timeRange: [
        new Date(new Date().getTime()-24*60*60*1000),
        new Date() ,
      ],
      pickerMinDate: null,
      dateLimit: 48*60*60*1000,
      disabledDate:(time) => {
        //console.log("???? "+time)
        return false;
      },
      drawerOpen:false, //右侧选择时间的抽屉
      tableData:[],
      tableHeight: 600,

      pointList:[], //图钉列表
      dialogShow:[], //图钉显示弹出框

      isAuto: false,
      nowIndex: 0,
      mapLevel:18,
    }
  },
  components: {},
  created(){

    that = this;

  },
  beforeUnmount(){
    window.removeEventListener('message', this.iframeEventListener)
  },
  watch:{

  },
  mounted(){
    //监听iframe子页面的回调
    window.addEventListener('message', this.iframeEventListener)


    window.addEventListener('keydown', (e) => {
      if (e.key == "Control")  this.onCtrl = true
    })
    window.addEventListener('keyup', (e) => {
      if (e.key == "Control")  this.onCtrl = false
    })

    window.addEventListener("resize", () => {
      this.tableHeight = window.innerHeight - 145;
    })

    setInterval(() => {
      if (!this.isAuto) return;
      if(!this.tableData || this.nowIndex >= this.tableData.length) return;
      console.error(this.tableData[this.nowIndex], this.nowIndex)
      this.gpsPointClick(this.tableData[this.nowIndex])
      if (this.nowIndex === this.tableData.length-1) this.nowIndex = 0;
      else this.nowIndex = this.nowIndex + 1 ;
    }, 1000);
  },
  methods:{
    handleClose(){},
    autoSelect(){
        this.isAuto = !this.isAuto;
    },
    gpsPointClick(row){
      this.nowIndex = row.index;
      let p = [];
      for (let point of that.pointList) {
        if (point.sn == 'qi_dian' || point.sn == 'zhong_dian'){
          p.push(point);
        }
      }


      if (row.id != 'qi_dian' && row.id != 'zhong_dian'){

        p.push({
          sn:'gps',
          name:row.id,
          pointType:'gps/gps',
          pointWidth: 50,
          pointHeight: 50,
          lng:row.lng,
          lat:row.lat,
          asl:0,
          hint:'<div style="background-color: #6bffc8" id="videoBallon'+row.id+'">' + row.id + '<div>',
          diyDialog: document.getElementById("balloon-popup-video").outerHTML
              .replace('[name]', row.date)
              .replace('[lnglat]', row.lng+","+row.lat)
              .replace('[speed]', row.speed),
          diyDialogX: -200/2,
          diyDialogY: -140,
          diyDialogAutoShow: false,
        })
        that.pointList = p;
        this.$refs.iframe.contentWindow.postMessage({
          tag:'initPoints',
          data:JSON.stringify(that.pointList)
        },this.mapSrc)
        this.$refs.iframe.contentWindow.postMessage({
          tag:'dialogShow',
          data:JSON.stringify(["qi_dian", "zhong_dian", "gps"])
        },this.mapSrc);
      }else{
        that.pointList = p;
        this.$refs.iframe.contentWindow.postMessage({
          tag:'initPoints',
          data:JSON.stringify(that.pointList)
        },this.mapSrc)
        this.$refs.iframe.contentWindow.postMessage({
          tag:'dialogShow',
          data:JSON.stringify(["qi_dian", "zhong_dian"])
        },this.mapSrc);
      }

      this.$refs.iframe.contentWindow.postMessage({
        tag:'flyToLnglat',
        data:JSON.stringify({lng:row.lng, lat:row.lat, level:that.mapLevel})
      },this.mapSrc)
    },
    drawerOpened(){
      this.tableHeight = window.innerHeight - 145;
    },
    timeSelect(){
      console.info(">>>>>>>>>>>>>>")
      this.drawerOpen = true

    },
    timeChange(){
      console.log(this.selectTime)
      let sT = new Date(this.selectDate.getTime());
      sT.setHours(this.selectTime[0].getHours(), this.selectTime[0].getMinutes(), this.selectTime[0].getSeconds())

      let eT = new Date(this.selectDate.getTime());
      eT.setHours(this.selectTime[1].getHours(), this.selectTime[1].getMinutes(), this.selectTime[1].getSeconds())

      this.startTime = sT.getTime()
      this.endTime = eT.getTime()
      this.initPath()
    },
    dateChange(){
      let sT = new Date(this.selectDate.getTime());
      sT.setHours(this.selectTime[0].getHours(), this.selectTime[0].getMinutes(), this.selectTime[0].getSeconds())

      let eT = new Date(this.selectDate.getTime());
      eT.setHours(this.selectTime[1].getHours(), this.selectTime[1].getMinutes(), this.selectTime[1].getSeconds())

      this.startTime = sT.getTime()
      this.endTime = eT.getTime()
      this.initPath()
    },
    setKeyStatus(code, status){
      console.error(code, status)
      switch (code) {
        case 17:
          if (this.onCtrl == status) return
          this.onCtrl = status
          break
      }
    },
    // 百度坐标转墨卡托 例如 let lon = 118.95229, lat = 29.03496;
    lonlatToMomercator(lon, lat){
      let x = 0, y = 0; //墨卡托坐标
      //  x = lon * 20037508.34 / 180;
      x = lon * 111308.55 + 40;
      y = Math.log(Math.tan((90+lat)*Math.PI/360))/(Math.PI/180);
      y = y * 111308.55;
      console.error(x + "," + y)
      return {x:x, y:y}
    },

    // this.mapSrc = 'http://map.yuncangai.com:37200/map/#/mapiframe?type=XZ&lng='+x+'&lat='+y+'&asl=500';
    iframeEventListener(e){
      console.log('获取子级B页面返回值:', e.data);
      if (e.data && e.data.tag && e.data.tag == 'loadingCompleted'){   //地图加载完成
        that.initPath();
      }else if (e.data && e.data.tag && e.data.tag == 'selectPoint'){   //编辑坐标时，选择新坐标点的回调
        that.bindClickEvent(e.data.data)
      }else if (e.data && e.data.tag && e.data.tag == 'clickGroup'){   //点击图钉时，图钉对应弹窗会隐藏，并返回该图钉的sn
        console.error('clickGroup', e.data.data)    //图钉的编号
      }else if (e.data && e.data.tag && e.data.tag == 'clickPoint'){   //点击图钉时，图钉对应弹窗会隐藏，并返回该图钉的sn
        console.error('clickPoint', e.data.data, that.onCtrl)    //图钉的编号
      }else if (e.data && e.data.tag && e.data.tag == 'enterPoint'){   //鼠标移动到图钉上时， 图钉对应弹窗会显示， 并返回该图钉的sn
        console.error('enterPoint', e.data.data)    //图钉的编号
      }else if (e.data && e.data.tag && e.data.tag == 'mapButtonClick'){   //图钉 上弹框的点击事件
        if (e.data.data && e.data.data.eventName == "openCameraBySn") {

        }

      }else if (e.data && e.data.tag && e.data.tag == 'boxSelection'){   //框选结果
        if (e.data.data) {
          console.error(e.data.data)  //起始点和结束点坐标
        }

      }else if (e.data && e.data.tag && e.data.tag == 'getViewBounds'){   //获取视野范围结果
        if (e.data.data) {
          console.error(e.data.data)  //
        }

      }else if (e.data && e.data.tag && e.data.tag == 'viewChange'){   //视野范围发生改变回调
        if (e.data.data) {
          console.error(e.data.data)  //
          that.mapLevel = e.data.data.level
        }

      }
    },
    //加载设备的坐标信息，生成图钉
    initPoints(){
      //调用子页面 initPoints方法，载入图钉
      this.$refs.iframe.contentWindow.postMessage({
        tag:'initPoints',
        data:JSON.stringify(this.deviceList)
      },this.mapSrc)
      this.$refs.iframe.contentWindow.postMessage({
        tag:'getViewBounds',
        data:null
      },this.mapSrc)

    },
    initPath(){
      //调用子页面 initPath方法，载入轨迹
      this.getGPSTrack();
    },



    fixed(val){
      return val == null ? '--' : val.toFixed(4)
    },

    //地图选点 回调  得到坐标信息
    bindClickEvent(info){
      this.cameraForm.lng = info.lng;
      this.cameraForm.lat = info.lat;
      this.cameraForm.asl = info.asl;

    },

    //地图视角移动到某设备
    toPoint(row){

      this.$refs.iframe.contentWindow.postMessage({
        tag:'initPoints',
        data:JSON.stringify(this.deviceList)
      },this.mapSrc)
      this.$refs.iframe.contentWindow.postMessage({
        tag:'getViewBounds',
        data:null
      },this.mapSrc)

      row.level = 22;
      this.$refs.iframe.contentWindow.postMessage({
        tag:'toPoint',
        data:JSON.stringify({sn:row.sn, level: 20})
      },this.mapSrc)



      /* if (this.iframeContent){
           this.iframeContent.toPoint(row);
       }*/
    },

    getGPSTrack(){
      this.apiUtils.syGPSTrack({deviceId:this.sn, startTime: this.startTime, endTime: this.endTime}).then(res => {
        if (res.code == 200){
          this.traceList = res.data
          let arr = [];
          let arr2 = [];
          for (let i = 0; i < this.traceList.length; i++) {
            let item = this.traceList[this.traceList.length - i - 1]
            let point = gps_to_bd(item.latitude, item.longitude)
            arr.push({
              lng: point.lon,
              lat: point.lat,
              date: item.gpsTime,
            })
            arr2.push({
              index: i,
              id: i,
              lng: parseFloat(point.lon).toFixed(5),
              lat: parseFloat(point.lat).toFixed(5),
              speed: item.speed ? parseFloat(item.speed).toFixed(4) : '-',
              date: item.gpsTime
            })
          }

          this.tableData = arr2
          this.$refs.iframe.contentWindow.postMessage({
            tag:'initPath',
            data:JSON.stringify(arr)
          },this.mapSrc)

          let p = [];

          if (arr.length > 0){
            this.tableData[0].id = 'qi_dian'
            p.push({
              sn:'qi_dian',
              name:arr2[0].id,
              pointType:'gps/qi_dian',
              pointWidth: 50,
              pointHeight: 50,
              lng:arr2[0].lng,
              lat:arr2[0].lat,
              asl:0,
              hint:'<div style="background-color: #6bffc8" id="videoBallon'+arr2[0].id+'">' + arr2[0].id + '<div>',
              diyDialog: document.getElementById("balloon-popup-video").outerHTML
                  .replace('[name]', arr2[0].date)
                  .replace('[lnglat]', arr2[0].lng+","+arr2[0].lat)
                  .replace('[speed]', arr2[0].speed),
              diyDialogX: -200/2,
              diyDialogY: -140,
              diyDialogAutoShow: false,
            });


            this.$refs.iframe.contentWindow.postMessage({
              tag:'flyToLnglat',
              data:JSON.stringify({lng:arr[0].lng, lat:arr[0].lat, level:that.mapLevel})
            },this.mapSrc)
          }
          if (arr.length>1){
            let row = arr2[arr2.length-1]
            this.tableData[this.tableData.length-1].id = 'zhong_dian'
            p.push({
              sn:'zhong_dian',
              name:row.id,
              pointType:'gps/zhong_dian',
              pointWidth: 50,
              pointHeight: 50,
              lng:row.lng,
              lat:row.lat,
              asl:0,
              hint:'<div style="background-color: #6bffc8" id="videoBallon'+row.id+'">' + row.id + '<div>',
              diyDialog: document.getElementById("balloon-popup-video").outerHTML
                  .replace('[name]', row.date)
                  .replace('[lnglat]', row.lng+","+row.lat)
                  .replace('[speed]', row.speed),
              diyDialogX: -200/2,
              diyDialogY: -140,
              diyDialogAutoShow: false,
            });
          }

          that.pointList = p;
          this.$refs.iframe.contentWindow.postMessage({
            tag:'initPoints',
            data:JSON.stringify(that.pointList)
          },this.mapSrc)
          this.$refs.iframe.contentWindow.postMessage({
            tag:'dialogShow',
            data:JSON.stringify(["qi_dian", "zhong_dian"])
          },this.mapSrc);

        } else {
          this.$message.error("获取轨迹失败! " + res.errInfo);
          console.log(res);
        }

      });
    },
  }
}
</script>
<style scoped>


.layui-layer-iframe  {
  background-color: #000;
}
.layui-layer-content  {
  height: calc(100% - 45px) !important;
}
.layui-layer-content>iframe{
  height: 100% !important;
}

.layui-layer-title{
  background-color: #03112C;
  color: #fff;
  border-bottom: 0px solid #eee;
}
.layui-layer-max{
  filter:invert(100%);
}
.layui-layer-min{
  filter:invert(100%);
}
.layui-layer-close{
  filter:invert(100%);
}
/* .layui-layer-max:after{
      content: '';
      display: block;
      height: 100%;
      background: difference;
      filter: drop-shadow(0px 0 0 #fff);
  }*/
</style>
<style scoped>
.nameHint {
  color: #fff;
  font-size: medium;
  line-height: 30px;
  margin: 0;
}
.snHint {
  color: #cbcbcb;
  font-size: small;
  line-height: 20px;
  margin: 0;
}



</style>